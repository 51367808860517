import { Alert, Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormLabel, RadioGroup, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { get, post, put } from 'src/api/client';
import { useGetLocale } from 'src/hooks/useGetLocale';
import { Config } from 'src/types/configuration';
import { OptionType } from 'src/types/project';
import { SiteInput } from 'src/types/siteForms';
import { countries } from '../../../constants/flags';
import { timeZones } from '../../../constants/timeZones';


export const ConfigurationForm = () => {
    const { locale } = useGetLocale();
    const navigate = useNavigate();
    const {
        formState: { isSubmitSuccessful },
        handleSubmit
    } = useForm<SiteInput>();
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [selectedTimezone, setSelectedTimeZone] = useState<OptionType | null>(null);
    const [config, setConfig] = useState<Config>();
    const [scheduleNever, setScheduleNever] = useState<boolean>(false);
    const [scheduleDaily, setScheduleDaily] = useState<boolean>(false);
    const [scheduleMonthly, setScheduleMonthly] = useState<boolean>(false);
    const [scheduleWeekly, setScheduleWeekly] = useState<boolean>(false);
    const [scheduleTime, setScheduleTime] = useState<Dayjs | null>(dayjs().set('hours', 8).set('minute', 0));
    const [annualDays, setAnnualDays] = useState<number | string>(20);
    const [hasError, setHasError] = useState<boolean>(false);

    const handleOnChange = (_: SyntheticEvent<Element, Event>, value: OptionType | null) => {
        if (value) {
            setSelectedOption(value);
        }
    };

    const handleOnChangeTimeZone = (_: SyntheticEvent<Element, Event>, value: OptionType | null) => {
        if (value) {
            setSelectedTimeZone(value);
        }
    };
    const handleOnChangeNever = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setScheduleNever(checked);
        if (checked){
            setScheduleDaily(false);
            setScheduleMonthly(false);
            setScheduleWeekly(false);
        }
    };
    const handleOnChangeDaily = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setScheduleDaily(checked);
        if (checked){
            setScheduleNever(false);
        }
    };
    const handleOnChangeMonthly = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setScheduleMonthly(checked);
        if (checked){
            setScheduleNever(false);
        }
    };
    const handleOnChangeWeekly = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setScheduleWeekly(checked);
        if (checked){
            setScheduleNever(false);
        }
    };

    const handleOnChangeScheduleTime = (value: Dayjs | null) => {
        if (value) {
            setScheduleTime(value);
        }
    };

    const handleOnChangeAnnualDays = (event:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const regex = /^[0-9\b]+$/;
        if (regex.test(event.target.value) && +event.target.value >= 0){
            setAnnualDays(+event.target.value)
            setHasError(false)
        }else{
            setHasError(true)
        }
        if (!event.target.value){
            setAnnualDays(event.target.value)
        }
    }
    useEffect(() => {
        (async () => {
            const siteConfig = await get('config?create=true');
            if (JSON.parse(siteConfig.data.success)) {
                setConfig(siteConfig.data.siteConfig);
            }
        })();
    }, []);
    useEffect(() => {
        if (isSubmitSuccessful && !hasError) {
            localStorage.removeItem('locale');
            navigate('/');
            window.location.reload();
        }
    }, [isSubmitSuccessful]);

    useEffect(() => {
        if (config) {
            for (const country of countries) {
                if (country.id == config.localeCode) {
                    setSelectedOption(country);
                }
            }
            setScheduleDaily(config.cronSchedulerDaily ?? false);
            setScheduleWeekly(config.cronSchedulerWeekly ?? false);
            setScheduleMonthly(config.cronSchedulerMonthly ?? false);
            if (!config.cronSchedulerDaily && !config.cronSchedulerWeekly && !config.cronSchedulerMonthly ){
                setScheduleNever(true);
            }
            
            if (config.timeZone) {
                for (const timeZone of timeZones) {
                    if (timeZone.id == config.timeZone) {
                        setSelectedTimeZone(timeZone);
                    }
                }
            }
            if (config.scheduleTime) {
                setScheduleTime(dayjs(config.scheduleTime));
            }
            if (config.annualDays) {
                setAnnualDays(config.annualDays);
            }
        }
    }, [config]);

    const onSubmitHandler: SubmitHandler<SiteInput> = async (_: SiteInput) => {
        if (selectedOption && !hasError) {
            const [_, ...rest] = selectedOption.label.split(' ');
            const value = {
                localeName: rest.join(' '),
                localeCode: selectedOption.id,
                cronSchedulerDaily: scheduleDaily,
                cronSchedulerWeekly: scheduleWeekly,
                cronSchedulerMonthly: scheduleMonthly,
                annualDays: annualDays,
                timeZone: selectedTimezone?.id,
                scheduleTime: scheduleTime
            };
            if (config) {
                await put('config', JSON.stringify(value));
            } else {
                await post('config', JSON.stringify(value));
            }
        }
    };

    return (
        <>
            {locale && (
                <LocalizationProvider adapterLocale={locale.toLocaleLowerCase()} dateAdapter={AdapterDayjs}>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormControl style={{ minWidth: '100%', minHeight: '85vh' }}>
                            <Grid2
                                container
                                spacing={3}
                                justifyContent="center"
                                sx={{
                                    margin: 'auto',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                {isSubmitSuccessful && (
                                    <Alert severity="success" sx={{ maxWidth: { xs: '50%', md: '40%', xl: '25%' }, marginLeft: 'auto', marginRight: 'auto' }}>
                                        Successfully updated configuration
                                    </Alert>
                                )}
                                <Grid2 xs={12}>
                                    <Typography component="h3" variant="h6">
                                        Configuration
                                    </Typography>
                                </Grid2>
                                <Grid2 xs={10}>
                                    <Autocomplete
                                        options={countries}
                                        value={selectedOption}
                                        onChange={handleOnChange}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        getOptionLabel={(option) => option?.label || ''}
                                        renderInput={(params) => <TextField {...params} label="Country" />}
                                    />
                                </Grid2>
                                <Grid2 xs={10}>
                                    <Autocomplete
                                        options={timeZones}
                                        value={selectedTimezone}
                                        onChange={handleOnChangeTimeZone}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        getOptionLabel={(option) => option.label || ''}
                                        renderInput={(params) => <TextField {...params} label="Time Zone" />}
                                    />
                                </Grid2>

                                <Grid2 xs={10}>
                                    <FormLabel>Missing Report Email Schedule</FormLabel>
                                    <RadioGroup row style={{ justifyContent: 'center' }}>
                                        <FormControlLabel value="never" control={<Checkbox onChange={handleOnChangeNever} checked={scheduleNever} />} label="Never" />
                                        <FormControlLabel value="daily" name="daily" control={<Checkbox onChange={handleOnChangeDaily} checked={scheduleDaily} />} label="Every Day" />
                                        <FormControlLabel value="weekly" name="weekly" control={<Checkbox onChange={handleOnChangeWeekly} checked={scheduleWeekly} />} label="Every Week" />
                                        <FormControlLabel value="monthly" name="monthly" control={<Checkbox onChange={handleOnChangeMonthly} checked={scheduleMonthly} />} label="Every Month" />
                                    </RadioGroup>
                                </Grid2>
                                <Grid2 xs={10} md={4}>
                                    <TimeField
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth={true}
                                        label="Select the time of sending emails"
                                        variant="outlined"
                                        format="HH:mm"
                                        helperText={'The email will be sent on the first day after the end of the period'}
                                        value={scheduleTime}
                                        onChange={handleOnChangeScheduleTime}
                                    />
                                </Grid2>
                                <Grid2 xs={12}>
                                </Grid2>
                                <Grid2  xs={10} md={4}>
                                    <TextField InputLabelProps={{ shrink: true }} fullWidth={true} value={ annualDays } error={ hasError } onChange={handleOnChangeAnnualDays} id="annual-days" label="Days of Annual Leave" variant="outlined" />
                                </Grid2>
                                <Grid2 xs={12}>
                                    <Button type="submit" variant="contained">
                                        Save Settings
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </FormControl>
                    </form>
                </LocalizationProvider>
            )}
        </>
    );
};
