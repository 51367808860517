import { boolean, object, string, TypeOf } from 'zod';

export const CreateUserSchema = object({
    firstName: string().nonempty('First Name is required').max(32, 'First Name must be less than 32 characters'),
    lastName: string().nonempty('Last Name is required').max(32, 'firstName must be less than 32 characters'),
    username: string().nonempty('Username is required').max(32, 'Username must be less than 32 characters'),
    email: string().nonempty('Email is required').email('Email is invalid'),
    password: string().nonempty('Password is required').min(8, 'Password must be more than 8 characters').max(32, 'Password must be less than 32 characters'),
    passwordConfirm: string().nonempty('Please confirm your password'),
    jobTitle: string(),
    phoneNumber: string(),
    role: string(),
    sendEmails: boolean().optional(),
}).refine((data) => data.password === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Passwords do not match'
});

export type CreateUserInput = TypeOf<typeof CreateUserSchema>;

export const UpdateUserSchema = object({
    firstName: string().nonempty('First Name is required').max(32, 'First Name must be less than 32 characters'),
    lastName: string().nonempty('Last Name is required').max(32, 'firstName must be less than 32 characters'),
    username: string().nonempty('Username is required').max(32, 'Username must be less than 32 characters'),
    email: string().nonempty('Email is required').email('Email is invalid'),
    oldPassword: string().optional().or(string().min(8, 'Password must be more than 8 characters').max(32, 'Password must be less than 32 characters')),
    password: string().optional().or(string().min(8, 'Password must be more than 8 characters').max(32, 'Password must be less than 32 characters')),
    passwordConfirm: string().optional().or(string().min(8, 'Password must be more than 8 characters').max(32, 'Password must be less than 32 characters')),
    jobTitle: string(),
    phoneNumber: string(),
    sendEmails: boolean().optional(),
}).refine((data) => data.password === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Passwords do not match'
});

export type UpdateUserInput = {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    oldPassword?: string;
    password?: string;
    passwordConfirm?: string;
    jobTitle?: string;
    phoneNumber?: string;
    role?: string;
    sendEmails: boolean;
};

export const LoginSchema = object({
    username: string().nonempty('Username is required'),
    password: string().nonempty('Password is required')
});

export type LoginInput = {
    username: string;
    password: string;
    rememberMe: boolean;
};

export const ForgottenPasswordSchema = object({
    email: string().nonempty('Email is required').email('Email is invalid'),
    username: string().nonempty('Username is required')
});

export type ForgottenPasswordInput = TypeOf<typeof ForgottenPasswordSchema>;

export const ResetPasswordSchema = object({
    password: string().optional().or(string().min(8, 'Password must be more than 8 characters').max(32, 'Password must be less than 32 characters')),
    passwordConfirm: string().optional().or(string().min(8, 'Password must be more than 8 characters').max(32, 'Password must be less than 32 characters'))
}).refine((data) => data.password === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Passwords do not match'
});

export type ResetPasswordInput = TypeOf<typeof ResetPasswordSchema>;
