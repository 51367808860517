import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import PendingIcon from '@mui/icons-material/Pending';
import { ButtonGroup, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Fragment, useEffect, useState } from 'react';
import { DeleteHolidayDialog } from 'src/components';
import { useGetLocale } from 'src/hooks/useGetLocale';
import { Holiday } from 'src/types/holiday';

export const HolidayRow = ({ holiday }: { holiday: Holiday }) => {
    const [canDelete, setCanDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState<string>('secondary.contrastText')
    const { locale } = useGetLocale();

    holiday.startDate = new Date(holiday.startDate);
    holiday.endDate = new Date(holiday.endDate);

    useEffect(() => {
        if (holiday.status == 'pending') {
            setCanDelete(true);
        }
    }, [holiday]);
    useEffect(() => {
        if (holiday.workOnPublicHoliday) {
            setBackgroundColor('success.contrastText')
        }
    }, [holiday.workOnPublicHoliday]);
    const handleClickDelete = () => {
        setOpen(true);
    };
    return (
        <Fragment>
            <DeleteHolidayDialog holiday={holiday} setOpenModal={setOpen} openModal={open} />
            <TableRow sx={{ backgroundColor: backgroundColor }}>
                <TableCell align="left">{locale && holiday.startDate.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' })}</TableCell>
                <TableCell align="center">{locale && holiday.endDate.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' })}</TableCell>
                <TableCell align="center">{holiday.daysCount}</TableCell>
                <TableCell align="center">
                    {holiday.status == 'pending' ? (
                        <PendingIcon fontSize="large" />
                    ) : holiday.status == 'verified' ? (
                        <CheckCircleIcon fontSize="large" color="success" />
                    ) : (
                        <CancelIcon color="error" fontSize="large" />
                    )}
                </TableCell>
                <TableCell align="center">
                    <ButtonGroup disableElevation variant="contained" color="secondary">
                        <PopupState variant="popover">
                            {(popupState) => (
                                <>
                                    <IconButton
                                        sx={{
                                            color: 'secondary.contrastText',
                                            backgroundColor: 'secondary.main'
                                        }}
                                        component="label"
                                        aria-label="info"
                                        {...bindTrigger(popupState)}
                                    >
                                        <DescriptionIcon />
                                    </IconButton>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center'
                                        }}
                                    >
                                        <Typography sx={{ p: 2 }}>{holiday.description}</Typography>
                                    </Popover>
                                </>
                            )}
                        </PopupState>
                        {canDelete && (
                            <IconButton
                                sx={{
                                    color: 'secondary.contrastText',
                                    backgroundColor: 'error.main'
                                }}
                                component="label"
                                aria-label="delete"
                                onClick={handleClickDelete}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </ButtonGroup>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};
