import { Fab, Typography, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ProjectTable } from 'src/components';
import { useGetIUserInformation } from 'src/hooks';
import { SummaryProjectsFilter } from 'src/components/filters/projectsFilter';
export const ListProjects = () => {
    const { IUser } = useGetIUserInformation();
    const navigate = useNavigate();
    return (
        <Fragment>
            <Grid2 container={true} spacing={2} justifyContent={'center'} sx={{ marginTop: 15, textAlign: 'center' }}>
                <Grid2 xs={12}>
                    <Typography component="h3" variant="h4">
                        Projects
                    </Typography>
                    <Divider sx={{ maxWidth: '30%', margin: 'auto' }} />
                </Grid2>
                <Grid2 xs={8}>
                    <SummaryProjectsFilter />
                </Grid2>
                <Grid2 xs={11}>
                    <ProjectTable />
                </Grid2>
            </Grid2>
            {IUser.role == 'admin' && (
                <Fab
                    sx={{ right: 20, position: 'absolute' }}
                    color="secondary"
                    aria-label="add"
                    onClick={() => {
                        navigate('/projects/create');
                    }}
                >
                    <AddIcon />
                </Fab>
            )}
        </Fragment>
    );
};
