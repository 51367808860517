import { object, string, boolean } from 'zod';

export const CreateUpdateProjectSchema = object({
    name: string().nonempty('Name is required').max(32, ' Name must be less than 100 characters'),
    isActive: boolean()
});

export type CreateUpdateProjectInput = {
    name: string;
    isActive?: boolean;
    users?: string[];
};
