import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import PendingIcon from '@mui/icons-material/Pending';
import { ButtonGroup, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Fragment, useEffect, useState } from 'react';
import { DeleteHolidayDialog, UpdateHolidayDialog, VerifyHolidayDialog } from 'src/components';
import { useGetLocale } from 'src/hooks/useGetLocale';
import { Holiday } from 'src/types/holiday';

export const PendingVerificationRow = ({ holiday }: { holiday: Holiday }) => {
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [backgroundColor, setBackgroundColor] = useState<string>('secondary.contrastText');

    const { locale } = useGetLocale();
    holiday.startDate = new Date(holiday.startDate);
    holiday.endDate = new Date(holiday.endDate);

    const isOnHoliday = () => {
        const today = new Date();
        return holiday.startDate <= today && today <= holiday.endDate;
    }
    const handleClickVerify = () => {
        setOpen(true);
        setIsVerified(true);
    };
    const handleClickDecline = () => {
        setOpen(true);
        setIsVerified(false);
    };
    const handleClickEdit = () => {
        setOpenEdit(true);
    };
    useEffect(() => {
        if (holiday.workOnPublicHoliday) {
            setBackgroundColor('success.contrastText')
        }
        else if (isOnHoliday()) {
            setBackgroundColor('success.light')
        }
        else{
            setBackgroundColor('secondary.contrastText')
        }
    }, [holiday]);
    const handleClickDelete = () => {
        setOpenDelete(true);
    };
    return (
        <Fragment>
            <DeleteHolidayDialog holiday={holiday} setOpenModal={setOpenDelete} openModal={openDelete} />
            <VerifyHolidayDialog holiday={holiday} setOpenModal={setOpen} openModal={open} isVerified={isVerified} />
            <UpdateHolidayDialog holiday={holiday} setIsOpen={setOpenEdit} isOpen={openEdit} />
            <TableRow sx={{ backgroundColor: backgroundColor }}>
                <TableCell align="left">
                    {holiday.user.firstName} {holiday.user.lastName}
                </TableCell>
                <TableCell align="center">{locale && holiday.startDate.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' })}</TableCell>
                <TableCell align="center">{locale && holiday.endDate.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' })}</TableCell>
                <TableCell align="center">{holiday.daysCount}</TableCell>
                <TableCell align="center">
                    <PopupState variant="popover">
                        {(popupState) => (
                            <>
                                <IconButton
                                    sx={{
                                        color: 'secondary.contrastText',
                                        backgroundColor: 'secondary.main'
                                    }}
                                    component="label"
                                    aria-label="info"
                                    {...bindTrigger(popupState)}
                                >
                                    <DescriptionIcon />
                                </IconButton>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center'
                                    }}
                                >
                                    <Typography sx={{ p: 2 }}>{holiday.description}</Typography>
                                </Popover>
                            </>
                        )}
                    </PopupState>
                </TableCell>
                <TableCell align="center">
                    {holiday.status == 'pending' ? (
                        <PendingIcon fontSize="large" />
                    ) : holiday.status == 'verified' ? (
                        <CheckCircleIcon fontSize="large" color="success" />
                    ) : (
                        <CancelIcon color="error" fontSize="large" />
                    )}
                </TableCell>
                <TableCell align="center">
                    <ButtonGroup disableElevation variant="contained" color="secondary">
                        {holiday.status == 'pending' && (
                            <>
                                <IconButton
                                    sx={{
                                        color: 'secondary.contrastText',
                                        backgroundColor: 'success.main'
                                    }}
                                    component="label"
                                    aria-label="accept"
                                    onClick={handleClickVerify}
                                >
                                    <DoneIcon />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        color: 'secondary.contrastText',
                                        backgroundColor: 'error.main'
                                    }}
                                    component="label"
                                    aria-label="decline"
                                    onClick={handleClickDecline}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </>
                        )}
                        <IconButton
                            sx={{
                                color: 'secondary.contrastText',
                                backgroundColor: 'secondary.main'
                            }}
                            component="label"
                            aria-label="edit"
                            onClick={handleClickEdit}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            sx={{
                                color: 'secondary.contrastText',
                                backgroundColor: 'error.main'
                            }}
                            component="label"
                            aria-label="delete"
                            onClick={handleClickDelete}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};
