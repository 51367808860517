import dayjs from 'dayjs';
import Grid2 from '@mui/material/Unstable_Grid2';
import {
    StatusFilter,
    AutocompleteHolidayUserFilter,
    DatePickerEndFilter,
    DatePickerStartFilter,
} from 'src/components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetLocale } from '../../../hooks/useGetLocale';
import { useDateFilter } from '../../../hooks';

export const HolidayFilters = () => {
    const { locale } = useGetLocale();
    const { handleOnChangeStart, startDate ,handleOnChangeEnd, endDate } = useDateFilter({ startDateInput: dayjs().startOf('year'), endDateInput: dayjs().endOf('year') });
    return (
        <> {locale && (
            <Grid2 xs={11} justifyContent={'center'}
                sx={{ marginTop: 2, textAlign: 'center', display: { xs: 'block', md: 'flex' } }}>
                <LocalizationProvider adapterLocale={locale.toLocaleLowerCase()} dateAdapter={AdapterDayjs}>
                    <Grid2 xs={12} md={3}>
                        <DatePickerStartFilter startDate={startDate} handleOnChangeStart={handleOnChangeStart}/>
                    </Grid2>
                    <Grid2 xs={12} md={3}>
                        <DatePickerEndFilter endDate={endDate} handleOnChangeEnd={handleOnChangeEnd}/>
                    </Grid2>
                </LocalizationProvider>
                <Grid2 xs={12} md={3}>
                    <AutocompleteHolidayUserFilter/>
                </Grid2>
                <Grid2 xs={12} md={3}>
                    <StatusFilter/>
                </Grid2>
            </Grid2>
        )} </>
    );
};
