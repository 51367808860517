import { Link, LinkProps } from 'react-router-dom';

export const StyledLink = (props: LinkProps) => (
    <Link
        {...props}
        style={{
            textDecoration: 'none'
        }}
    >
        {props.children}
    </Link>
);
