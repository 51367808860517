import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LayoutComponent, ProtectedRoute, ProtectedRouteAdmin, ProtectedRouteModerator, RedirectRoute } from 'src/components';
import { useGetIUserInformation } from 'src/hooks';
import {
    ConfigurationPage,
    CreateUpdateProject,
    CreateUpdateUser,
    ForgottenPassword,
    HolidaysPage,
    Home,
    ListProjects,
    ListUsers,
    Login,
    NotFound,
    ResetPassword
} from 'src/pages';

export const Router = () => {
    const token = localStorage.getItem('token');
    const { IUser } = useGetIUserInformation();
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LayoutComponent />}>
                    <Route element={<ProtectedRoute token={token} />}>
                        <Route element={<ProtectedRouteAdmin token={token} />}>
                            <Route path="users/create" element={<CreateUpdateUser id={undefined} />} />
                            <Route path="projects/create" element={<CreateUpdateProject />} />
                            <Route path="configuration" element={<ConfigurationPage />} />
                        </Route>
                        <Route element={<ProtectedRouteModerator token={token} />}>
                            <Route path="users" element={<ListUsers />} />
                            <Route path="users/update/:userId" element={<CreateUpdateUser id={undefined} />} />
                            <Route path="projects" element={<ListProjects />} />
                            <Route path="projects/update/:id" element={<CreateUpdateProject />} />
                        </Route>
                        <Route index element={<Home />} />
                        <Route path="profile" element={<CreateUpdateUser id={IUser.id} />} />
                        <Route path="holidays" element={<HolidaysPage role={IUser.role}/>} />
                    </Route>
                    <Route element={<RedirectRoute user={IUser} redirectPath={'/'} />}>
                        <Route path="/login" element={<Login />} />
                        <Route path="/forgotten-password" element={<ForgottenPassword />} />
                        <Route path="/forgotten-password/:id" element={<ResetPassword />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
