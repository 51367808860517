import { Box, CircularProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get } from 'src/api/client';
import { TaskProjectRow, TaskRow, TaskUserRow } from 'src/components';
import { useGetIUserInformation, useGetUserSummary } from 'src/hooks';
import { GetUserResponse, ListTaskResponse, PaginationResponse } from 'src/types/response';
import { Task } from 'src/types/task';

export const TaskTable = () => {
    const { IUser } = useGetIUserInformation();
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const [projectIds, setProjectIds] = useState<string[] | null>();
    const [userIds, setUserIds] = useState<string[] | null>();
    const [tasks, setTasks] = useState<Task[]>([]);
    const [pagination, setPagination] = useState<PaginationResponse>();
    const [page, setPage] = useState<number>(1);
    const [tasksLoading, setTasksLoading] = useState<boolean>(true);
    const [usersLoading, setUsersLoading] = useState<boolean>(true);
    const [projectsLoading, setProjectsLoading] = useState<boolean>(true);
    const { users, projects } = useGetUserSummary(IUser);
    const selectedTaskId = searchParams.get('taskId');

    useEffect(() => {
        if (searchParams.getAll('projects').length) {
            setProjectIds(searchParams.getAll('projects'));
        } else if (IUser.id) {
            (async () => {
                const projectResponse: GetUserResponse = await get(`users/${IUser.id}?project=true`);
                const projects = [];

                for (const project of projectResponse.data.user.projects) {
                    projects.push(project.id);
                }
                setProjectIds(projects);
            })();
        }
    }, [IUser.id, searchParams]);

    useEffect(() => {
        setUserIds(searchParams.getAll('users') ?? []);
    }, [searchParams]);

    useEffect(() => {
        (async () => {
            if (startDate && endDate && IUser.id) {
                if (!selectedTaskId) {
                    setTasksLoading(true);
                    setUsersLoading(true);
                    setProjectsLoading(true);
                }
                const searchParameters = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    page: page.toString() || '1'
                });

                if (projectIds?.length) {
                    for (const projectId of projectIds) {
                        searchParameters.append('projects', projectId);
                    }
                } else {
                    searchParameters.append('projects', '0');
                }
                if (userIds?.length) {
                    for (const userId of userIds) {
                        searchParameters.append('users', userId);
                    }
                }
                const endpoint = `tasks?${searchParameters.toString()}`;
                const response: ListTaskResponse = await get(endpoint);
                for (const task of response.data.items) {
                    const hours = Math.floor(task.timeSpentMinutes / 60);
                    const minutes = task.timeSpentMinutes % 60;
                    if (minutes < 10) {
                        task.timeSpent = `${hours}:0${minutes}`;
                    } else {
                        task.timeSpent = `${hours}:${minutes}`;
                    }
                }
                if (response.data.success) {
                    setTasks(response.data.items);
                    setPagination(response.data.pagination);
                    setTasksLoading(false);
                }
            }
        })();

    }, [users, projects]);

    const handleChange = (_: ChangeEvent<unknown>, page: number) => {
        setPage(page);
        searchParams.set('page', page.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        setUsersLoading(users ? false : true);
        setProjectsLoading(projects ? false : true);
    }, [users, projects, searchParams]);

    return (
        <Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="time reports">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '20%' }} align="center">
                                Date
                            </TableCell>
                            <TableCell sx={{ width: '20%' }} align="center">
                                Project
                            </TableCell>
                            <TableCell sx={{ width: '20%' }} align="center">
                                User
                            </TableCell>
                            <TableCell sx={{ width: '20%' }} align="center">
                                Name
                            </TableCell>
                            <TableCell sx={{ width: '10%' }} align="center">
                                Spent Time
                            </TableCell>
                            <TableCell sx={{ width: '10%' }} align="center">
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tasksLoading ? (
                            <TableRow>
                                <TableCell component="th" scope="row" align="center" colSpan={6}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            tasks.map((task) => <TaskRow key={task.id} task={task}
                                isMyTask={task.UserId == +IUser.id || IUser.role == 'admin' || IUser.role == 'moderator'}
                                selectedTask={task.id.toString() === selectedTaskId}/>)
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper}>
                <Table aria-label="users summary">
                    <TableHead sx={{ backgroundColor: 'success.contrastText' }}>
                        <TableRow>
                            <TableCell align="left" width="33%">
                                User Summary
                            </TableCell>
                            <TableCell align="center" width="33%">
                                Time Spent
                            </TableCell>
                            <TableCell align="center" width="33%">
                                Export
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersLoading ? (
                            <TableRow>
                                <TableCell component="th" scope="row" align="center" colSpan={3}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            users?.map((user) => <TaskUserRow key={user.id} user={user} />)
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper}>
                <Table aria-label="projects summary">
                    <TableHead sx={{ backgroundColor: 'success.contrastText' }}>
                        <TableRow>
                            <TableCell align="left" width="33%">
                                Project Summary
                            </TableCell>
                            <TableCell align="center" width="33%">
                                Time Spent
                            </TableCell>
                            <TableCell align="center" width="33%">
                                Export
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectsLoading ? (
                            <TableRow>
                                <TableCell component="th" scope="row" align="center" colSpan={3}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            projects?.map((project) => <TaskProjectRow key={project.id} project={project} />)
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'inline-flex', padding: '1%' }}>
                <Pagination count={pagination?.count_of_pages || 1} page={page} onChange={handleChange}
                    color="primary"/>
            </Box>
        </Fragment>
    );
};
