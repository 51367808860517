import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get } from 'src/api/client';
import { OptionType } from 'src/types/project';
import { ListUserResponse } from 'src/types/response';
import { useGetIUserInformation } from '../../../hooks';

export const UserSearchFilter = () => {
    const { IUser } = useGetIUserInformation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [options, setOptions] = useState<OptionType[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<OptionType[]>([]);


    useEffect(() => {
        (async () => {
            const userIds = searchParams.getAll('users');
            if (userIds.length > 0 && options.length > 0) {
                const selectedOptions = options.filter(option => userIds.includes(option.id));
                setSelectedUsers(selectedOptions);
            }
        })();
    }, [searchParams]);

    useEffect(() => {
        (async () => {
            const searchParameters = new URLSearchParams({
                isDeleted: true.toString()
            });
            const response: ListUserResponse = await get(`users?${searchParameters.toString()}`);
            const userOptions: OptionType[] = response.data.items.map((user) => ({
                label: `${user.firstName} ${user.lastName}`,
                id: user.id.toString(),
            }));
            setOptions(userOptions);

        })();
    }, [IUser.id]);

    const handleOnChange = (_: SyntheticEvent<Element, Event>, value: OptionType[]) => {
        const newUserIds: string[] = value?.map((user) => user.id)
        searchParams.delete('users');

        if (newUserIds.length > 0) {
            for (const userId of newUserIds) {
                searchParams.append('users', userId);
            }
        }
        setSearchParams(searchParams);
        setSelectedUsers(value || []);
    }

    return (
        <Autocomplete
            multiple
            id='search-users-autocomplete'
            options={options}
            value={selectedUsers}
            onChange={handleOnChange}
            disablePortal
            renderInput={(params) => <TextField {...params} label='Users'/>}
        />
    );
}
