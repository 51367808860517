import AddIcon from '@mui/icons-material/Add';
import { Divider, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get } from 'src/api/client';
import { CreateHolidayDialog, HolidayRow } from 'src/components';
import { Holiday } from 'src/types/holiday';
import { ListHolidaysResponse } from 'src/types/response';

export const HolidayTable = ({ userId }: { userId: string }) => {
    const [holidays, setHolidays] = useState<Holiday[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        if (searchParams.get('holiday')) {
            searchParams.delete('holiday');
            setSearchParams(searchParams);
        }
        (async () => {
            const firstDayOfTheYear = dayjs().startOf('year').format('YYYY-MM-DD');
            const endDayOfTheYear = dayjs().endOf('year').format('YYYY-MM-DD');
            const searchParameters = new URLSearchParams({
                users: userId,
                startDate: firstDayOfTheYear,
                endDate: endDayOfTheYear,
                limit:'all'
            });
            const response: ListHolidaysResponse = await get(`days?${searchParameters.toString()}`);
            setHolidays(response.data.items);
        })();
    }, [searchParams.get('holiday')]);

    useEffect(() => {
        let totalDays = 0;
        for (const holiday of holidays) {
            if (holiday.status == 'verified') {
                totalDays += holiday.daysCount;
            }
        }
        setTotal(totalDays);
    }, [holidays]);
    return (
        <>
            <Grid2 container={true} spacing={2} justifyContent={'center'} sx={{ marginTop: 15, textAlign: 'center' }}>
                <Grid2 xs={12}>
                    <Typography component="h3" variant="h4">
                        Holidays
                    </Typography>
                    <Divider sx={{ maxWidth: '50%', margin: 'auto' }} />
                </Grid2>
                <CreateHolidayDialog isOpen={openDialog} setIsOpen={setOpenDialog} userId={userId} />
                <TableContainer sx={{ width: { xs: '100%', md: '80%' }, marginTop: 2 }} component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Start Date</TableCell>
                                <TableCell align="center">End Date</TableCell>
                                <TableCell align="center">Days Count</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {holidays?.map((holiday) => (
                                <HolidayRow key={holiday.id} holiday={holiday} />
                            ))}
                            {holidays && (
                                <TableRow sx={{ backgroundColor: 'success.contrastText' }}>
                                    <TableCell align="left">Total</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">{total}</TableCell>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid2>
            <Fab
                sx={{ right: 20, position: 'absolute' }}
                color="secondary"
                aria-label="add"
                onClick={() => {
                    setOpenDialog(!openDialog);
                }}
            >
                <AddIcon />
            </Fab>
        </>
    );
};
