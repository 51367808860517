import { UpdateUserForm, CreateUserForm } from 'src/components';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { User } from 'src/types/user';
import { GetUserResponse } from 'src/types/response';
import { get } from 'src/api/client';

export const CreateUpdateUser = ({ id }: { id: string | undefined }) => {
    const [user, setUser] = useState<User>();
    const [userIdentificator, setUserID] = useState<string>();
    const { userId } = useParams<{ userId: string }>();

    useEffect(() => {
        setUserID(id ?? userId);
    }, [id, userId]);

    useEffect(() => {
        (async () => {
            if (userIdentificator) {
                const response: GetUserResponse = await get(`users/${userIdentificator}`);
                setUser(response.data.user);
            }
        })();
    }, [userIdentificator]);
    if (user) {
        return <UpdateUserForm user={user} />;
    }
    return <CreateUserForm />;
};
