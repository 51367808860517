import { DialogTitle, DialogContent, Dialog, DialogContentText, DialogActions, Button } from '@mui/material';
import { User } from 'src/types/user';
import { Transition } from 'src/components';
import { destroy } from 'src/api/client';

export const DeleteUserDialog = ({
    user,
    setIsDeleted,
    openModal,
    setOpenModal
}: {
    user: User;
    setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleCloseAgree = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const userId = event.currentTarget.id;
        await destroy(`users/${userId}`);
        setOpenModal(false);
        setIsDeleted(true);
    };

    return (
        <Dialog open={openModal} TransitionComponent={Transition} keepMounted aria-describedby="delete-user">
            <DialogTitle>{'Are you sure?'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Delete user: {user.firstName} {user.lastName}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ backgroundColor: 'primary.main', color: 'secondary.contrastText' }} onClick={handleClose}>
                    Disagree
                </Button>
                <Button sx={{ backgroundColor: 'error.main', color: 'secondary.contrastText' }} id={user.id} onClick={handleCloseAgree}>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};
