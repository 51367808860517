import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get } from 'src/api/client';
import { useGetIUserInformation } from 'src/hooks';
import { OptionType } from 'src/types/project';
import { GetUserResponse, ListUserResponse } from 'src/types/response';

export const AutocompleteUserFilter = () => {
    const { IUser } = useGetIUserInformation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [userLabels, setUserLabels] = useState<OptionType[]>([]);
    const [options, setOptions] = useState<OptionType[]>([{ label: 'There are no users', id: '0' }]);
    const [userIds, setUserIds] = useState<string[] | undefined>(searchParams.get('users')?.split(','));

    useEffect(() => {
        if (IUser.id) {
            (async () => {
                const projectResponse: GetUserResponse = await get(`users/${IUser.id}?project=true`);
                const projectIds = [];
                for (const project of projectResponse.data.user.projects) {
                    projectIds.push(project.id);
                }
                const userOptions = [];
                const searchParameters = new URLSearchParams({
                    isDeleted: true.toString()
                });
                if (projectIds && projectIds.length > 0) {
                    for (const projectId of projectIds) {
                        searchParameters.append('projects', projectId);
                    }
                    const users: ListUserResponse = await get(`users?${searchParameters.toString()}`);
                    for (const user of users.data.items) {
                        userOptions.push({ label: user.firstName + ' ' + user.lastName, id: user.id });
                    }
                } else {
                    const userResponse: GetUserResponse = await get(`users/${IUser.id}?${searchParameters.toString()}`);
                    const user = userResponse.data.user;
                    userOptions.push({ label: user.firstName + ' ' + user.lastName, id: user.id });
                }
                setOptions(userOptions);
            })();
        }
    }, [IUser.id]);

    useEffect(() => {
        if (userIds == null) return;
        const users: Array<OptionType> = [];
        for (const userId of userIds) {
            const foundOption = options.find((userLabels) => userLabels.id == userId);
            if (foundOption) {
                users.push(foundOption);
            }
        }
        setUserLabels(users);
    }, [userIds, options]);
    useEffect(() => {
        const users = searchParams.getAll('users');
        if (users.length) {
            setUserIds(users);
        } else {
            setUserIds([]);
        }
    }, [searchParams]);

    const handleOnChange = (_: SyntheticEvent<Element, Event>, value: OptionType[] | null) => {
        if (value) {
            const newUserIds: string[] = [];
            for (const user of value) {
                newUserIds.push(user.id);
            }
            searchParams.delete('users');
            for (const userId of newUserIds) {
                searchParams.append('users', userId);
            }
            setSearchParams(searchParams);
            setUserIds(newUserIds);
        }
    };
    return (
        <Autocomplete
            options={options}
            value={userLabels}
            onChange={handleOnChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.label}
            multiple
            renderInput={(params) => <TextField {...params} label="Users" />}
        />
    );
};
