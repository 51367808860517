import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OptionType } from 'src/types/project';
import { ListProjecstResponse } from 'src/types/response';
import { get } from 'src/api/client';


export const AutocompleteProjectSummaryFilter = () => {
    const [options, setOptions] = useState<Array<OptionType>>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedProjects, setSelectedProjects] = useState<Array<OptionType>>([]);
    const [selectedProjectIds, setSelectedProjectIds] = useState<Array<string>>(searchParams.getAll('projects'));


    useEffect(() => {
        (async () => {
            const updatedSelectedProjects: Array<OptionType> = [];
            const projectsResponse: ListProjecstResponse = await get('projects');
            const projectOptions: OptionType[] = projectsResponse.data.items.map(project => ({
                label: project.name,
                id: project.id.toString()
            }));

            projectOptions.sort((a, b) => a.label.localeCompare(b.label));

            setOptions(projectOptions);

            for (const projectId of selectedProjectIds) {
                const foundProject = projectOptions.find((option) => option.id === projectId);
                if (foundProject) {
                    updatedSelectedProjects.push(foundProject);
                }
            }
            setSelectedProjects(updatedSelectedProjects);

        })()
    }, [searchParams]);

    const handleOnChange = (_: SyntheticEvent, value: OptionType[] | null) => {
        const chosenProjectIds = value?.map(project => project.id) || [];
        const updatedSearchParams = new URLSearchParams();
        const status = searchParams.get('status')

        if (status) {
            updatedSearchParams.set('status', status);
        }

        if (chosenProjectIds.length > 0) {
            chosenProjectIds.forEach(projectId => {
                updatedSearchParams.append('projects', projectId);
            });
        }

        setSearchParams(updatedSearchParams);
        setSelectedProjectIds(chosenProjectIds);
    };

    return (
        <Autocomplete
            options={options}
            value={selectedProjects}
            getOptionDisabled={(option) => option.id === '0'}
            onChange={handleOnChange}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={(option) => option.label}
            multiple
            renderInput={(params) => <TextField {...params} label="Projects" />}
        />
    );
};
