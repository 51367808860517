import { Divider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { HolidayFilters, PendingVerificationTable, UserSpecificHolidayTable } from 'src/components';

export const PendingVerification = () => {
    return (
        <Grid2 container={true} spacing={2} justifyContent={'center'} sx={{ marginTop: 15, textAlign: 'center' }}>
            <Grid2 xs={12}>
                <Typography component="h3" variant="h4">
                    Holidays
                </Typography>
                <Divider sx={{ maxWidth: '50%', margin: 'auto' }} />
            </Grid2>
            <HolidayFilters />
            <Grid2 xs={11}>
                <PendingVerificationTable />
            </Grid2>
            <Grid2 xs={11}>
                <UserSpecificHolidayTable />
            </Grid2>
        </Grid2>
    );
};
