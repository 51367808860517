import { TextField, Autocomplete } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { OptionType } from 'src/types/project';
import { useSearchParams } from 'react-router-dom';
import { ListUserResponse } from 'src/types/response';
import { get } from 'src/api/client';

export const AutocompleteHolidayUserFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [userLabels, setUserLabels] = useState<OptionType[]>([]);
    const [options, setOptions] = useState<OptionType[]>([{ label: 'There are no users', id: '0' }]);
    const [userIds, setUserIds] = useState<Array<string> | undefined>(searchParams.get('users')?.split(','));

    useEffect(() => {
        (async () => {
            const users: ListUserResponse = await get(`users`);
            const userOptions = [];
            for (const user of users.data.items) {
                userOptions.push({ label: `${user.firstName} ${user.lastName}`, id: user.id });
            }
            setOptions(userOptions);
        })();
    }, []);

    useEffect(() => {
        if (userIds) {
            const users: Array<OptionType> = [];
            for (const userId of userIds) {
                const foundOption = options.find((userLabels) => userLabels.id == userId);
                if (foundOption) {
                    users.push(foundOption);
                }
            }
            setUserLabels(users);
        }
    }, [searchParams, options]);

    const handleOnChange = (_: SyntheticEvent<Element, Event>, value: OptionType[] | null) => {
        if (!value) return;

        const newUserIds: string[] = [];
        for (const user of value) {
            newUserIds.push(user.id);
        }
        if (searchParams.get('users')) {
            searchParams.delete('users');
        }
        for (const userId of newUserIds) {
            searchParams.append('users', userId);
        }
        setSearchParams(searchParams);
        setUserIds(newUserIds);
    };

    return (
        <Autocomplete
            options={options}
            value={userLabels}
            onChange={handleOnChange}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={(option) => option.label}
            multiple
            renderInput={(params) => <TextField {...params} label="Users" />}
        />
    );
};
