import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Holidays } from 'src/components';
import { useGetLocale } from 'src/hooks/useGetLocale';
import { PendingVerification } from '../pendingVerification';

export const HolidaysPage = ({ role } : {role: string}) => {
    const { locale } = useGetLocale();
    if (role && role != 'admin')
        return (
            <>
                {locale && (
                    <LocalizationProvider adapterLocale={locale.toLocaleLowerCase()} dateAdapter={AdapterDayjs}>
                        <Holidays />
                    </LocalizationProvider>
                )} 
            </>
        );
    else {
        return (
            <PendingVerification/>
        )
    }
};
