import { useEffect, useState } from 'react';
import { DialogTitle, Divider, DialogContent, Dialog } from '@mui/material';
import { StateDialogContext } from 'src/types/stateContext';
import { HolidayForm, Transition } from 'src/components';

export const UpdateHolidayDialog = ({ isOpen, setIsOpen, holiday }: StateDialogContext) => {
    useEffect(() => {
        setIsOpen(isOpen);
    }, [isOpen]);
    const [userId, setUserId] = useState<string>();
    useEffect(() => {
        if (holiday?.user.id) {
            setUserId(holiday?.user.id);
        }
    }, [holiday]);

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} TransitionComponent={Transition} keepMounted fullWidth={true} maxWidth={'md'} sx={{ textAlign: 'center' }}>
            <DialogTitle>Edit Holiday</DialogTitle>
            <DialogContent>
                <Divider variant="middle" />
                {isOpen && userId && <HolidayForm userId={userId} setIsOpen={setIsOpen} isOpen={isOpen} holiday={holiday} />}
            </DialogContent>
        </Dialog>
    );
};
