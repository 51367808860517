import { DateValidationError } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import { Dayjs } from 'dayjs';

export const DatePickerEndFilter = ({ endDate, handleOnChangeEnd }: {
    endDate: Dayjs, handleOnChangeEnd: (value: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => void
}) => {
    return <DatePicker value={endDate} label={'To Date'} slotProps={{ textField: { fullWidth: true } }}
        onChange={handleOnChangeEnd}/>;
};
