import { IconButton, ButtonGroup, TableCell, TableRow, Popover, Typography, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { useNavigate, Link } from 'react-router-dom';
import { Project } from 'src/types/project';
import { useState, useEffect, Fragment } from 'react';
import { put, destroy } from 'src/api/client';
import { useGetIUserInformation } from 'src/hooks';

export const ProjectRow = ({ project }: { project: Project }) => {
    const navigate = useNavigate();
    const { IUser } = useGetIUserInformation();
    const [isDeleted, setIsDeleted] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const popOverUserOpen = Boolean(anchorEl);
    const popoverUserId = popOverUserOpen ? 'user-popover' : undefined;

    const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleRestore = () => {
        const restoreProject = async () => {
            await put(`projects/${project.id}`, JSON.stringify({ restore: true }));
        };
        restoreProject();
        setIsDeleted(false);
    };
    const handleDelete = () => {
        const deleteProject = async () => {
            await destroy(`projects/${project.id}`);
        };
        deleteProject();
        setIsDeleted(true);
    };
    useEffect(() => {
        setIsDeleted(!!project.deletedAt);
    }, [project]);

    return (
        <Fragment>
            <TableRow>
                <TableCell component="th" scope="row">
                    <Link
                        to={{
                            pathname: `update/${project.id}`
                        }}
                    >
                        {project.name}
                    </Link>
                </TableCell>
                <TableCell align="center">{project.isActive ? <CheckCircleIcon color="success"/> :
                    <CancelIcon color="error"/>}</TableCell>
                <TableCell align="center">
                    <Button
                        aria-describedby={popoverUserId}
                        variant="text"
                        onClick={handlePopoverOpen}
                    >
                        {project.users.length}
                    </Button>
                </TableCell>
                <TableCell align="center">
                    {!isDeleted ? (
                        <ButtonGroup disableElevation variant="contained" color="secondary">
                            <IconButton
                                sx={{
                                    color: 'secondary.contrastText',
                                    backgroundColor: 'secondary.main'
                                }}
                                component="label"
                                aria-label="edit"
                                onClick={() => navigate(`update/${project.id}`)}
                            >
                                <EditIcon />
                            </IconButton>
                            {IUser.role == 'admin' && (
                                <IconButton
                                    sx={{
                                        color: 'secondary.contrastText',
                                        backgroundColor: 'error.main'
                                    }}
                                    component="label"
                                    aria-label="delete"
                                    onClick={handleDelete}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </ButtonGroup>
                    ) : (
                        <IconButton
                            sx={{
                                color: 'secondary.contrastText',
                                backgroundColor: 'success.main'
                            }}
                            component="label"
                            aria-label="restore"
                            onClick={handleRestore}
                        >
                            <FlipCameraAndroidIcon />
                        </IconButton>
                    )}
                </TableCell>
                <Popover
                    id={popoverUserId}
                    open={popOverUserOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography sx={{ p: 2 }}>
                        {project.users.map((user, index) => (
                            <div key={index}>
                                {`${user.firstName} ${user.lastName}`}
                            </div>
                        ))}
                    </Typography>
                </Popover>
            </TableRow>
        </Fragment>
    );
};
