import { Typography, Divider } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TaskFilters, TaskTable } from 'src/components';
import { useSetLocale } from '../../hooks/useSetLocale';

export const Home = () => {
    const { locale, canSetLocale } = useSetLocale();
    return (
        <>
            {canSetLocale ? (
                <LocalizationProvider adapterLocale={locale.toLocaleLowerCase()} dateAdapter={AdapterDayjs}>
                    <Grid2 container={true} spacing={2} justifyContent={'center'} sx={{ marginTop: 15, textAlign: 'center' }}>
                        <Grid2 xs={12}>
                            <Typography component="h3" variant="h4">
                                Time Reports
                            </Typography>
                            <Divider sx={{ maxWidth: '50%', margin: 'auto' }} />
                        </Grid2>
                        <TaskFilters />
                        <Grid2 xs={11}>
                            <TaskTable />
                        </Grid2>
                    </Grid2>
                </LocalizationProvider>
            ) : (
                <> </>
            )}
        </>
    );
};
