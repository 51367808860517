import { createTheme } from '@mui/material/styles';

export const mainTheme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Sans-Serif',
                    color: 'inherit',
                    textDecoration: 'none'
                }
            }
        },
        MuiMenu: {
            defaultProps: {
                keepMounted: true,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#343a40'
        },
        secondary: {
            main: '#37C6FF',
            contrastText: '#fff'
        },
        success: {
            main: '#30cb00',
            light: '#6CFFB6',
            contrastText: '#FFFACD'
        },
        error: {
            main: '#B00020'
        }
    }
});
