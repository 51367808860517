import { OptionType } from './project';

export const userStatusOptions: OptionType[] = [
    { label: 'Active', id: 'active' },
    { label: 'Inactive', id: 'inactive' }
];

export const userRoleOptions: OptionType[] = [
    { label: 'Admin', id: 'admin' },
    { label: 'Moderator', id: 'moderator' },
    { label: 'Developer', id: 'developer' }
];
