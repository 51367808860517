import { Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
    const navigate = useNavigate();
    return (
        <Grid2
            container
            justifyContent="center"
            direction={'column'}
            columns={16}
            sx={{
                alignItems: 'center',
                textAlign: 'center',
                minHeight: '85vh'
            }}
        >
            <Grid2 columns={8} justifyContent={'center'}>
                <img src="/images/404-Error.jpg" alt="404" width={500} height={250} />
            </Grid2>
            <Grid2 columns={8} justifyContent={'center'}>
                <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
                    Back Home
                </Button>
            </Grid2>
        </Grid2>
    );
};
