import { useState, useEffect } from 'react';
import { get } from 'src/api/client';
import { User } from 'src/types/user';
import { ListUserResponse } from 'src/types/response';

export const useAutocompleteProject = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<readonly User[]>([]);

    useEffect(() => {
        setLoading((open && options.length === 0) ?? false);
    }, [options, open]);

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const usersResponse: ListUserResponse = await get('users');
            if (active) {
                setOptions([...usersResponse.data.items]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return { open, setOpen, options, setOptions, loading };
};
