import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OptionType } from 'src/types/project';
import { userRoleOptions } from 'src/types/userOptions';

export const UserRoleFilter = () => {
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [roleId, setRoleId] = useState<string>();

    useEffect(() => {
        if (roleId) {
            const foundOption = userRoleOptions.find((role) => role.id == roleId);
            if (foundOption) {
                setSelectedOption(foundOption);
            }
        } else {
            setSelectedOption(null);
        }
    }, [roleId]);

    useEffect(() => {
        setRoleId(searchParams.get('role') ?? '');
    }, [searchParams]);

    const handleOnChange = (_: SyntheticEvent<Element, Event>, value: OptionType | null) => {
        if (value) {
            searchParams.set('role', value.id);
            setSearchParams(searchParams);
        } else {
            searchParams.delete('role');
            setSearchParams(searchParams);
        }
    };
    return (
        <Autocomplete
            options={userRoleOptions}
            value={selectedOption}
            onChange={handleOnChange}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={(option) => option?.label || ''}
            renderInput={(params) => <TextField {...params} label="Role" />}
        />
    );
};
