import Grid2 from '@mui/material/Unstable_Grid2';
import { AutocompleteProjectSummaryFilter } from '../autocompleteProjectTableFilter';
import { ProjectStatusFilter } from '../projectStatusFilter';

export const SummaryProjectsFilter = () => {
    return (
        <>
            <Grid2 xs={11} sx={{ marginTop: 2, display: { xs: 'block', md: 'flex' }, marginBottom: 2 }}>
                <Grid2 xs={12} md={6}>
                    <ProjectStatusFilter />
                </Grid2>
                <Grid2 xs={12} md={6}>
                    <AutocompleteProjectSummaryFilter />
                </Grid2>
            </Grid2>
        </>
    );
};
