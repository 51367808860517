import { Fragment, useEffect, useState } from 'react';
import { IconButton, ButtonGroup, TableRow, TableCell } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { User } from 'src/types/user';
import { useNavigate, Link } from 'react-router-dom';
import { DeleteUserDialog } from 'src/components';
import { put } from 'src/api/client';

export const UserRow = ({ user }: { user: User }) => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const handleClickOpen = () => {
        setOpenModal(!openModal);
    };
    const handleRestore = () => {
        const restoreUser = async () => {
            await put(`users/${user.id}`, JSON.stringify({ restore: true }));
        };
        restoreUser();
        setIsDeleted(false);
    };
    useEffect(() => {
        setIsDeleted(!!user.deletedAt);
    }, [user]);

    return (
        <Fragment>
            <DeleteUserDialog user={user} setIsDeleted={setIsDeleted} openModal={openModal} setOpenModal={setOpenModal} />
            <TableRow>
                <TableCell component="th" scope="row">
                    <Link
                        to={{
                            pathname: `update/${user.id}`
                        }}
                    >
                        {user.username}
                    </Link>
                </TableCell>
                <TableCell align="center">{user.firstName}</TableCell>
                <TableCell align="center">{user.lastName}</TableCell>
                <TableCell align="center">{user.email}</TableCell>
                <TableCell align="center">{user.role}</TableCell>
                <TableCell align="center">{user.jobTitle}</TableCell>
                <TableCell align="center">{user.phoneNumber}</TableCell>
                <TableCell align="center">
                    {!isDeleted ? (
                        <ButtonGroup disableElevation variant="contained">
                            <IconButton
                                sx={{
                                    color: 'secondary.contrastText',
                                    backgroundColor: 'secondary.main'
                                }}
                                component="label"
                                aria-label="edit"
                                onClick={() => navigate(`update/${user.id}`)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                sx={{
                                    color: 'secondary.contrastText',
                                    backgroundColor: 'error.main'
                                }}
                                component="label"
                                aria-label="delete"
                                onClick={handleClickOpen}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ButtonGroup>
                    ) : (
                        <IconButton
                            sx={{
                                color: 'secondary.contrastText',
                                backgroundColor: 'success.main'
                            }}
                            component="label"
                            aria-label="restore"
                            onClick={handleRestore}
                        >
                            <FlipCameraAndroidIcon />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
        </Fragment>
    );
};
