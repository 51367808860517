import { useState, useEffect } from 'react';
import { get } from 'src/api/client';
import { GetUserResponse, ListProjecstResponse } from 'src/types/response';
import { OptionType } from 'src/types/project';

export const useAutocompleteProjectOptions = (userId: string, isActive: boolean) => {
    const [options, setOptions] = useState<OptionType[]>([{ label: 'You do not have projects assigned', id: '0' }]);

    useEffect(() => {
        if (userId) {
            (async () => {
                const searchParameters = new URLSearchParams({});

                if (isActive) {
                    const projects: ListProjecstResponse = await get('projects');
                    if (projects.data.items.length) {
                        searchParameters.append('activeProjects', true.toString());
                    } else {
                        searchParameters.append('project', true.toString());
                    }
                } else {
                    searchParameters.append('project', true.toString());
                }
                const endpoint = `users/${userId}?${searchParameters.toString()}`;
                const projectResponse: GetUserResponse = await get(endpoint);
                const projectOptions = [];

                for (const project of projectResponse.data.user.projects) {
                    projectOptions.push({ label: project.name, id: project.id.toString() });
                }
                projectOptions.sort((projectA, projectB) => projectA.label.localeCompare(projectB.label));
                projectOptions.push({ label: '', id: '0' });
                setOptions(projectOptions);
            })();
        }
    }, [userId]);

    return { options, setOptions };
};
