import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { post } from 'src/api/client';
import { CreateUserResponse } from 'src/types/response';
import { CreateUserInput, CreateUserSchema } from 'src/types/userForms';

export const CreateUserForm = () => {
    const {
        register,
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit,
        setError
    } = useForm<CreateUserInput>({
        resolver: zodResolver(CreateUserSchema)
    });

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (isSubmitSuccessful) {
            setSuccess(true);
            reset();
        }
    }, [isSubmitSuccessful]);

    const onSubmitHandler: SubmitHandler<CreateUserInput> = async (values: CreateUserInput) => {
        const response: CreateUserResponse = await post('users', JSON.stringify(values));
        if (!response.data.success) {
            setError('username', { type: 'custom', message: 'This username is already taken' });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <FormControl>
                <Grid2
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{
                        marginTop: 8,
                        alignItems: 'center',
                        textAlign: 'center',
                        minHeight: '85vh'
                    }}
                >
                    {success && <Alert severity="success">User created successfully</Alert>}
                    <Grid2 xs={12}>
                        <Typography component="h3" variant="h6">
                            Login Information
                        </Typography>
                    </Grid2>
                    <Grid2 xs={9} sm={9} md={4}>
                        <TextField fullWidth label="Email*" variant="standard" error={!!errors?.email} helperText={errors?.email?.message} {...register('email')} />
                        <TextField fullWidth label="Username*" variant="standard" error={!!errors?.username} helperText={errors?.username?.message} {...register('username')} />
                    </Grid2>
                    <Grid2 xs={9} sm={9} md={4}>
                        <TextField fullWidth type="password" label="Password" variant="standard" error={!!errors?.password} helperText={errors?.password?.message} {...register('password')} />
                        <TextField
                            fullWidth
                            type="password"
                            label="Confirm Password"
                            variant="standard"
                            error={!!errors?.passwordConfirm}
                            helperText={errors?.passwordConfirm?.message}
                            {...register('passwordConfirm')}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <Typography component="h3" variant="h6">
                            Personal Information
                        </Typography>
                    </Grid2>
                    <Grid2 xs={9} sm={9} md={4}>
                        <TextField fullWidth label="First Name*" variant="standard" error={!!errors?.firstName} helperText={errors?.firstName?.message} {...register('firstName')} />
                        <TextField fullWidth label="Last Name*" variant="standard" error={!!errors?.lastName} helperText={errors?.lastName?.message} {...register('lastName')} />
                    </Grid2>
                    <Grid2 xs={9} sm={9} md={4}>
                        <TextField fullWidth label="Job Title" variant="standard" error={!!errors?.jobTitle} helperText={errors?.jobTitle?.message} {...register('jobTitle')} />
                        <TextField fullWidth label="Phone Number" variant="standard" error={!!errors?.phoneNumber} helperText={errors?.phoneNumber?.message} {...register('phoneNumber')} />
                    </Grid2>
                    <Grid2 xs={12}>
                        <FormLabel>Role</FormLabel>
                        <RadioGroup row defaultValue="developer" style={{ justifyContent: 'center' }}>
                            <FormControlLabel value="admin" control={<Radio {...register('role')} />} label="Admin" />
                            <FormControlLabel value="developer" name="developer" control={<Radio {...register('role')} />} label="Developer" />
                            <FormControlLabel value="moderator" name="moderator" control={<Radio {...register('role')} />} label="Moderator" />
                        </RadioGroup>
                    </Grid2>
                    <Grid2 xs={12}>
                        <FormControlLabel control={<Checkbox defaultChecked {...register('sendEmails')} />} label="Send missing reports email to this user" />
                    </Grid2>
                    <Grid2 xs={12}>
                        <Button type="submit" variant="contained">
                            Create User
                        </Button>
                    </Grid2>
                </Grid2>
            </FormControl>
        </form>
    );
};
