import React, { MouseEvent } from 'react';
import { IconButton, ButtonGroup, TableCell, TableRow } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { StyledLink } from 'src/components';
import { IUserSpentTime } from 'src/types/user';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';
import { useExport } from 'src/hooks';
import { headers } from 'src/constants/csvHeaders';

export const TaskUserRow = ({ user }: { user: IUserSpentTime }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const handleSetUser = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        searchParams.set('users', user.id);
        setSearchParams(searchParams);
    };

    const { fetchData, data, csvLink } = useExport(user.id, undefined);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell component="th" scope="row">
                    <StyledLink to="#" onClick={handleSetUser}>
                        {user.firstName + ' ' + user.lastName}
                    </StyledLink>
                </TableCell>
                <TableCell align="center">{user.timeSpent}</TableCell>
                <TableCell align="center">
                    <ButtonGroup disableElevation variant="contained" color="secondary">
                        <IconButton
                            sx={{
                                color: 'secondary.contrastText',
                                backgroundColor: 'success.main'
                            }}
                            component="label"
                            aria-label="export"
                            onClick={fetchData}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </ButtonGroup>
                    <CSVLink className="hidden" data={data} headers={headers} filename={`${user.firstName + '_' + user.lastName}.csv`} ref={csvLink} target="_blank" />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};
