import Grid2 from '@mui/material/Unstable_Grid2';
import { UserRoleFilter, UserSearchFilter, UserStatusFilter } from 'src/components';

export const UserFilter = () => {
    return (
        <Grid2 xs={11} sx={{ marginTop: 2, display: { xs: 'block', md: 'flex' }, marginBottom: 2 }}>
            <Grid2 xs={12} md={6}>
                <UserStatusFilter />
            </Grid2>
            <Grid2 xs={12} md={6}>
                <UserRoleFilter />
            </Grid2>
            <Grid2 xs={12} md={6}>
                <UserSearchFilter />
            </Grid2>
        </Grid2>
    );
};
