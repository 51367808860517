import { Navigate, Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { AuthenticatedUser } from 'src/types/user';

export const ProtectedRouteModerator = ({ token }: { token: string | null }) => {
    if (!token) {
        return <Navigate to="/login" replace />;
    } else {
        const user: AuthenticatedUser = jwt_decode(token);
        if (user.role == 'moderator' || user.role == 'admin') {
            return <Outlet />;
        }
    }
    return <Navigate to="/404" replace />;
};
