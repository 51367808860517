import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { post, put } from 'src/api/client';
import { Transition } from 'src/components';
import { CreateHolidaysResponse } from 'src/types/response';
import { StateDialogContext } from 'src/types/stateContext';

export const MoreThanAnnualDialog = ({ isOpen, setIsOpen, holiday, isCreate, values, setIsGlobalOpen }: StateDialogContext) => {
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        setIsOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setIsOpen(false);
        if (setIsGlobalOpen){
            setIsGlobalOpen(false);
        }
    };
    const handleAgree = async  () => {
        let response: CreateHolidaysResponse;
        if (isCreate) {
            response = await post('days', JSON.stringify(values));
        } else {
            response = await put(`days/${holiday?.id}`, JSON.stringify(values));
        }
        if (response.data.success) {
            setIsOpen(false);
            searchParams.append('holiday', 'true');
            setSearchParams(searchParams);
        }
        if (setIsGlobalOpen){
            setIsGlobalOpen(false);
        }
    };

    return (
        <Dialog open={isOpen} TransitionComponent={Transition} keepMounted fullWidth={true} maxWidth={'md'} sx={{ textAlign: 'center' }}>
            <DialogTitle>Confirm Holiday</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <Divider variant="middle" />
                <Typography sx={{ margin: 'auto', maxWidth: '40%', textAlign:'center', paddingTop:'20px' }} gutterBottom>Kindly confirm your intent to request time off, even if the accumulated leave days exceed the annual limit set by the administrator.</Typography>
            </DialogContent>
            <DialogActions>
                <Button sx={{ color: 'secondary.contrastText', backgroundColor: 'secondary.main' }} onClick={handleClose}>Disagree</Button>
                <Button sx={{ color: 'secondary.contrastText', backgroundColor: 'error.main' }} onClick={handleAgree} autoFocus>
            Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};
