import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OptionType } from 'src/types/project';
import { userStatusOptions } from 'src/types/userOptions';

export const UserStatusFilter = () => {
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [statusId, setStatusId] = useState<string>();

    useEffect(() => {
        if (statusId) {
            const foundOption = userStatusOptions.find((statuses) => statuses.id == statusId);
            if (foundOption) {
                setSelectedOption(foundOption);
            }
        } else {
            setSelectedOption(null);
        }
    }, [statusId]);

    useEffect(() => {
        setStatusId(searchParams.get('status') ?? '');
    }, [searchParams]);

    const handleOnChange = (_: SyntheticEvent<Element, Event>, value: OptionType | null) => {
        if (value) {
            searchParams.set('status', value.id);
            setSearchParams(searchParams);
        } else {
            searchParams.delete('status');
            setSearchParams(searchParams);
        }
    };
    return (
        <Autocomplete
            options={userStatusOptions}
            value={selectedOption}
            onChange={handleOnChange}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={(option) => option?.label || ''}
            renderInput={(params) => <TextField {...params} label="Status" />}
        />
    );
};
