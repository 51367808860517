import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { AuthenticatedUser } from 'src/types/user';

export const useGetIUserInformation = () => {
    const token = localStorage.getItem('token');
    const [IUser, setIUser] = useState<AuthenticatedUser>({} as AuthenticatedUser);

    if (token) {
        const user: AuthenticatedUser = jwt_decode(token);
        if (Date.now() >= user.exp * 1000) {
            localStorage.removeItem('token');
            window.location.reload();
        } else {
            useEffect(() => {
                setIUser(user);
            }, [token]);
        }
    }

    return { IUser };
};
