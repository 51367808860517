import { DateValidationError } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import { Dayjs } from 'dayjs';

export const DatePickerStartFilter = ({ startDate, handleOnChangeStart }: {
    startDate: Dayjs, handleOnChangeStart: (value: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => void
}) => {
    return <DatePicker value={startDate} label={'From Date'} slotProps={{ textField: { fullWidth: true } }}
        onChange={handleOnChangeStart}/>;
};
