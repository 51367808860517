import Grid2 from '@mui/material/Unstable_Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    DatePickerEndFilter,
    AutocompleteUserFilter,
    AutocompleteProjectFilter,
    DatePickerStartFilter
} from 'src/components';
import { useGetLocale } from 'src/hooks/useGetLocale';
import { useDateFilter } from '../../../hooks';
import dayjs from 'dayjs';

export const TaskFilters = () => {
    const { locale } = useGetLocale();
    const { handleOnChangeStart, startDate, handleOnChangeEnd, endDate } = useDateFilter({
        startDateInput: dayjs().date(1),
        endDateInput: dayjs()
    });

    return (
        <>
            {locale && (
                <Grid2 xs={11} sx={{ marginTop: 2, display: { xs: 'block', md: 'flex' }, marginBottom: 2 }}>
                    <LocalizationProvider adapterLocale={locale.toLocaleLowerCase()} dateAdapter={AdapterDayjs}>
                        <Grid2 xs={12} md={3}>
                            <DatePickerStartFilter startDate={startDate} handleOnChangeStart={handleOnChangeStart}/>
                        </Grid2>
                        <Grid2 xs={12} md={3}>
                            <DatePickerEndFilter endDate={endDate} handleOnChangeEnd={handleOnChangeEnd}/>
                        </Grid2>
                    </LocalizationProvider>
                    <Grid2 xs={12} md={3}>
                        <AutocompleteProjectFilter />
                    </Grid2>
                    <Grid2 xs={12} md={3}>
                        <AutocompleteUserFilter />
                    </Grid2>
                </Grid2>
            )}
        </>
    );
};
