import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { post } from 'src/api/client';
import { LoginResponse } from 'src/types/response';
import { LoginInput, LoginSchema } from 'src/types/userForms';

export const LoginForm = () => {
    const [rememberMeChecked, setRememberMeChecked] = useState<boolean>(false);
    const {
        register,
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit,
        setError
    } = useForm<LoginInput>({
        resolver: zodResolver(LoginSchema)
    });

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
            window.location.reload();
        }
    }, [isSubmitSuccessful]);

    const onSubmitHandler: SubmitHandler<LoginInput> = async (values: LoginInput) => {
        values.rememberMe = rememberMeChecked;
        const response: LoginResponse = await post('users/login', JSON.stringify(values));
        if (response && !response.data.success) {
            setError('password', { type: 'custom', message: 'Wrong password' });
        } else {
            const token = response.data.token;
            if (token) {
                localStorage.setItem('token', token);
            }
        }
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleRememberMe = () => setRememberMeChecked(!rememberMeChecked);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <FormControl style={{ minWidth: '100%', minHeight: '85vh' }}>
                <Grid2
                    container
                    spacing={3}
                    justifyContent="center"
                    sx={{
                        margin: 'auto',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}
                >
                    <Grid2 xs={12}>
                        <Typography component="h3" variant="h6">
                            Welcome
                        </Typography>
                    </Grid2>
                    <Grid2 xs={7} md={3}>
                        <TextField label="Username" fullWidth={true} variant="standard" error={!!errors?.username} helperText={errors?.username?.message} {...register('username')} />
                    </Grid2>
                    <Grid2 xs={7} md={3}>
                        <FormControl fullWidth={true}  variant="standard">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                error={!!errors?.password}
                                id='password'
                                {...register('password')}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={8}>
                        <FormControlLabel control={<Checkbox onChange={handleRememberMe} />} label="Remember me" />
                    </Grid2>
                    <Grid2 xs={8}>
                        <Link to="/forgotten-password" replace>
                            Forgotten password?
                        </Link>
                    </Grid2>
                    <Grid2 xs={8}>
                        <Button type="submit" variant="contained">
                            Log In
                        </Button>
                    </Grid2>
                </Grid2>
            </FormControl>
        </form>
    );
};
