import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get } from 'src/api/client';
import { CreateHolidayDialog, PendingVerificationRow } from 'src/components';
import { useGetIUserInformation } from 'src/hooks';
import { statusOptions } from 'src/types/dayOffStatusOptions';
import { Holiday } from 'src/types/holiday';
import { ListHolidaysResponse, PaginationResponse } from 'src/types/response';

export const PendingVerificationTable = () => {
    const [holidays, setHolidays] = useState<Holiday[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userIds, setUserIds] = useState<string[] | null>();
    const [statusId, setStatusId] = useState<string | null>();
    const [pagination, setPagination] = useState<PaginationResponse>();
    const [page, setPage] = useState<number>(1);
    const { IUser } = useGetIUserInformation()
    useEffect(() => {
        if (searchParams.get('holiday')) {
            searchParams.delete('holiday');
            setSearchParams(searchParams);
        }
        setUserIds(searchParams.getAll('users') ?? []);
    }, [searchParams]);

    useEffect(() => {
        setStatusId(searchParams.get('status') ?? '');
    }, [searchParams.get('status')]);

    const handleChange = (_: ChangeEvent<unknown>, page: number) => {
        setPage(page);
        searchParams.set('page', page.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        (async () => {
            const paramStartDate = searchParams.get('startDate');
            const paramEndDate = searchParams.get('endDate');
            const searchParameters = new URLSearchParams({
                startDate: paramStartDate ?? '',
                endDate: paramEndDate ?? '',
                includeUser: true.toString(),
                page: page.toString() || '1'
            });
            if (userIds) {
                for (const userId of userIds) {
                    searchParameters.append('users', userId);
                }
            }
            if (statusId) {
                const foundOption = statusOptions.find((statuses) => statuses.id == statusId);
                if (foundOption) {
                    searchParameters.append('status', foundOption.label.toLowerCase());
                }
            }
            const endpoint = `days?${searchParameters.toString()}`;
            const response: ListHolidaysResponse = await get(endpoint);
            setPagination(response.data.pagination);
            setHolidays(response.data.items);
        })();
    }, [userIds, statusId, page, searchParams]);
    return (
        <>
            <CreateHolidayDialog isOpen={openDialog} setIsOpen={setOpenDialog} userId={IUser.id} />
            <Grid2
                container={true}
                spacing={2}
                justifyContent={'center'}
                sx={{
                    textAlign: 'center'
                }}
            >
                <Grid2 xs={12}>
                    <TableContainer sx={{ marginTop: 2 }} component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">User</TableCell>
                                    <TableCell align="center">Start Date</TableCell>
                                    <TableCell align="center">End Date</TableCell>
                                    <TableCell align="center">Days Count</TableCell>
                                    <TableCell align="center">Description</TableCell>
                                    <TableCell align="center">Verified</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {holidays?.map((holiday) => (
                                    <PendingVerificationRow key={holiday.id} holiday={holiday} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid2>
                <Box sx={{ display: 'inline-flex', padding: '1%' }}>
                    <Pagination count={pagination?.count_of_pages || 1} page={page} onChange={handleChange} color="primary" />
                </Box>
            </Grid2>
            <Fab
                sx={{ right: 20, position: 'absolute' }}
                color="secondary"
                aria-label="add"
                onClick={() => {
                    setOpenDialog(!openDialog);
                }}
            >
                <AddIcon />
            </Fab>
        </>
    );
};
