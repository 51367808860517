import { get } from 'src/api/client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetUserResponse, ListUserResponse, projectsSpentTimeResponse, usersSpentTimeResponse } from 'src/types/response';
import { AuthenticatedUser, IUserSpentTime, User } from 'src/types/user';
import { IProjectSpentTime, Project } from 'src/types/project';

export const useGetUserSummary = (IUser: AuthenticatedUser) => {
    const [searchParams] = useSearchParams();
    const [projects, setProjects] = useState<IProjectSpentTime[]>();
    const [users, setUsers] = useState<IUserSpentTime[]>();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    useEffect(() => {
        if (IUser.id) {
            (async () => {
                const { projectIds } = await getProjectIds(IUser, searchParams);
                let { usersIdsList } = await getUserIds(IUser, projectIds, searchParams);
                if (!usersIdsList.length) {
                    usersIdsList = [IUser.id];
                }
                const searchParameters = new URLSearchParams({
                    users: usersIdsList.join(','),
                    projects: projectIds.toString() || '',
                    startDate: startDate || '',
                    endDate: endDate || '',
                    isDeleted: true.toString()
                });

                const usersEndpoint = `users/time-spent?${searchParameters.toString()}`;
                const usersSpentTime: usersSpentTimeResponse = await get(usersEndpoint);
                const users = usersSpentTime.data.items;
                const projectsSearchParameters = new URLSearchParams({
                    users: usersIdsList.join(','),
                    projects: projectIds.join(','),
                    startDate: startDate || '',
                    endDate: endDate || '',
                    isDeleted: true.toString()
                });
                const projectsEndpoint = `projects/time-spent?${projectsSearchParameters.toString()}`;
                const projectsSpentTime: projectsSpentTimeResponse = await get(projectsEndpoint);
                const projects = projectsSpentTime.data.items;

                setUsers(users);
                setProjects(projects);
            })();
        }
    }, [searchParams, IUser.id]);
    return { users, projects };
};

const getProjectIds = async (IUser: AuthenticatedUser, searchParams: URLSearchParams): Promise<{ projects: Project[]; projectIds: string[] }> => {
    const projectResponse: GetUserResponse = await get(`users/${IUser.id}?project=true`);
    const projectIds = [];
    const projects = [];
    for (const project of projectResponse.data.user.projects) {
        const searchedProjects = searchParams.getAll('projects');
        if (!searchedProjects.length) {
            projectIds.push(project.id);
            projects.push(project);
        }
        if (searchedProjects && searchedProjects.includes(project.id.toString())) {
            projects.push(project);
            projectIds.push(project.id);
        }
    }
    projects.sort((projectA, projectB) => projectA.name.localeCompare(projectB.name));
    return { projects, projectIds };
};

const getUserIds = async (IUser: AuthenticatedUser, projectIds: string[], searchParams: URLSearchParams): Promise<{ usersIdsList: string[] }> => {
    let users: User[] = [];
    if (projectIds && projectIds.length > 0) {
        const userResponse: ListUserResponse = await get(`users?projects=${projectIds.join(',')}&isDeleted=true`);
        users = userResponse.data.items;
    } else {
        const userResponse: GetUserResponse = await get(`users/${IUser.id}`);
        users = [userResponse.data.user];
    }
    let usersIdsList = [];
    const searchedUsers = searchParams.getAll('users');

    if (!searchedUsers.length) {
        for (const user of users) {
            usersIdsList.push(user.id);
        }
    } else {
        usersIdsList = searchedUsers;
    }

    return { usersIdsList };
};
