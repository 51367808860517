import { get } from 'src/api/client';
import { useEffect, useState } from 'react';
import { GetTaskResponse } from 'src/types/response';
import { Task } from 'src/types/task';

export const useGetTask = (taskId: string | undefined) => {
    const [task, setTask] = useState<Task>();
    useEffect(() => {
        (async () => {
            const response: GetTaskResponse = await get(`tasks/${taskId}?project=true`);
            setTask(response.data.task);
        })();
    }, [taskId]);
    return task;
};
