import { FormControl, Typography, Button, InputLabel, Input, InputAdornment, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { ResetPasswordInput, ResetPasswordSchema } from 'src/types/userForms';
import { zodResolver } from '@hookform/resolvers/zod';
import { put } from 'src/api/client';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

export const ResetPasswordForm = () => {
    const { id } = useParams<{ id: string }>();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    const {
        register,
        formState: { errors, isSubmitSuccessful },
        handleSubmit
    } = useForm<ResetPasswordInput>({
        resolver: zodResolver(ResetPasswordSchema)
    });

    useEffect(() => {
        if (isSubmitSuccessful) {
            navigate('/login');
        }
    }, [isSubmitSuccessful]);

    const onSubmitHandler: SubmitHandler<ResetPasswordInput> = async (values: ResetPasswordInput) => {
        if (id) {
            await put(`users/password-reset`, JSON.stringify(values), id);
            localStorage.removeItem('rememberMe');
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <FormControl style={{ minWidth: '100%', minHeight: '85vh' }}>
                    <Grid2
                        container
                        spacing={3}
                        justifyContent="center"
                        sx={{
                            margin: 'auto',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}
                    >
                        <Grid2 xs={12}>
                            <Typography component="h3" variant="h6">
                                Reset Password
                            </Typography>
                        </Grid2>
                        <Grid2 xs={8}>
                            <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                                <InputLabel htmlFor="Password">Password</InputLabel>
                                <Input
                                    error={!!errors?.password}
                                    {...register('password')}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={8}>
                            <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                                <InputLabel htmlFor="Password">Confirm Password</InputLabel>
                                <Input
                                    error={!!errors?.passwordConfirm}
                                    {...register('passwordConfirm')}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownConfirmPassword} edge="end">
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={8}>
                            <Button type="submit" variant="contained">
                                Reset
                            </Button>
                        </Grid2>
                    </Grid2>
                </FormControl>
            </form>
        </>
    );
};
