import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ProjectRow } from 'src/components';
import { ListProjecstResponse } from 'src/types/response';
import { get } from 'src/api/client';
import { Project } from 'src/types/project';

export const ProjectTable = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        (async () => {
            const statusFilter = searchParams.get('status');
            const projectFilter = searchParams.getAll('projects');
            const searchParameters = new URLSearchParams({
                users: true.toString(),
                isDeleted: true.toString(),
            });
            if (statusFilter) {
                searchParameters.set('status', statusFilter);
            }
            if (projectFilter.length > 0) {
                searchParameters.set('projects',projectFilter.toString());
            }
            const response: ListProjecstResponse = await get(`projects?${searchParameters.toString()}`);
            setProjects(response.data.items);
        })();
    }, [searchParams]);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="center">Is Active</TableCell>
                        <TableCell align="center">Users</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects?.map((project) => (
                        <ProjectRow key={project.id} project={project} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
