import { FormControl, TextField, Typography, Button, Alert } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ForgottenPasswordInput, ForgottenPasswordSchema } from 'src/types/userForms';
import { zodResolver } from '@hookform/resolvers/zod';
import { post } from 'src/api/client';

export const ForgottenPasswordForm = () => {
    const {
        register,
        formState: { errors, isSubmitSuccessful },
        handleSubmit,
        setError,
        reset
    } = useForm<ForgottenPasswordInput>({
        resolver: zodResolver(ForgottenPasswordSchema)
    });

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (isSubmitSuccessful) {
            setSuccess(true);
            reset();
        }
    }, [isSubmitSuccessful]);

    const onSubmitHandler: SubmitHandler<ForgottenPasswordInput> = async (values: ForgottenPasswordInput) => {
        const response = await post('users/forgotten-password', JSON.stringify(values));
        if (!response.data.success) {
            setError('username', { type: 'custom', message: 'Wrong username' });
            setError('email', { type: 'custom', message: 'Wrong email' });
        }
    };

    return (
        <>
            {success && (
                <Alert severity="success" sx={{ marginTop: 15, maxWidth: { xs: '50%', md: '40%', xl: '25%' }, marginLeft: 'auto', marginRight: 'auto' }}>
                    Please check your email!
                </Alert>
            )}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <FormControl style={{ minWidth: '100%', minHeight: '85vh' }}>
                    <Grid2
                        container
                        spacing={3}
                        justifyContent="center"
                        sx={{
                            margin: 'auto',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}
                    >
                        <Grid2 xs={12}>
                            <Typography component="h3" variant="h6">
                                Did you forget your password?
                            </Typography>
                        </Grid2>
                        <Grid2 xs={8}>
                            <TextField fullWidth label="Username" variant="standard" error={!!errors?.username} helperText={errors?.username?.message} {...register('username')} />
                        </Grid2>
                        <Grid2 xs={8}>
                            <TextField fullWidth label="Email" variant="standard" error={!!errors?.email} helperText={errors?.email?.message} {...register('email')} />
                        </Grid2>
                        <Grid2 xs={8}>
                            <Button type="submit" variant="contained">
                                Send Email
                            </Button>
                        </Grid2>
                    </Grid2>
                </FormControl>
            </form>
        </>
    );
};
