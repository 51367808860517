import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAutocompleteProjectOptions, useGetIUserInformation } from 'src/hooks';
import { OptionType } from 'src/types/project';

export const AutocompleteProjectFilter = () => {
    const { IUser } = useGetIUserInformation();
    const { options } = useAutocompleteProjectOptions(IUser.id, false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [projectLabels, setProjectLabels] = useState<Array<OptionType>>([]);
    const [projectIds, setProjectIds] = useState<Array<string> | undefined>(searchParams.getAll('projects'));
    useEffect(() => {
        if (projectIds == null) return;
        const projects: Array<OptionType> = [];
        for (const projectId of projectIds) {
            const foundOption = options.find((projectLabel) => projectLabel.id === projectId);
            if (foundOption) {
                projects.push(foundOption);
            }
        }
        setProjectLabels(projects);
    }, [options, projectIds]);

    useEffect(() => {
        const projects = searchParams.getAll('projects');
        if (projects.length) {
            setProjectIds(projects);
        } else {
            setProjectLabels([]);
        }
    }, [searchParams]);

    const handleOnChange = (_: SyntheticEvent<Element, Event>, value: OptionType[] | null) => {
        if (value) {
            const newProjectIds: string[] = [];
            for (const project of value) {
                newProjectIds.push(project.id);
            }
            searchParams.delete('projects');
            if (newProjectIds && newProjectIds.length) {
                for (const projectId of newProjectIds) {
                    searchParams.append('projects', projectId);
                }
            }
            setSearchParams(searchParams);
            setProjectIds(newProjectIds);
        }
    };

    return (
        <Autocomplete
            options={options}
            value={projectLabels}
            getOptionDisabled={(option) => option.id === '0'}
            onChange={handleOnChange}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={(option) => option.label}
            multiple
            renderInput={(params) => <TextField {...params} label="Projects" />}
        />
    );
};
