import { MouseEvent } from 'react';
import { IconButton, ButtonGroup, TableCell, TableRow } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { StyledLink } from 'src/components';
import DownloadIcon from '@mui/icons-material/Download';
import { IProjectSpentTime } from 'src/types/project';
import { CSVLink } from 'react-csv';
import { useExport } from 'src/hooks';
import { headers } from 'src/constants/csvHeaders';

export const TaskProjectRow = ({ project }: { project: IProjectSpentTime }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const handleSetProject = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        searchParams.set('projects', project.id);
        setSearchParams(searchParams);
    };
    const { fetchData, data, csvLink } = useExport(undefined, project.id);

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row">
                    <StyledLink to="#" onClick={handleSetProject}>
                        {project.name}
                    </StyledLink>
                </TableCell>
                <TableCell align="center">{project.timeSpent}</TableCell>
                <TableCell align="center">
                    <ButtonGroup disableElevation variant="contained" color="secondary">
                        <IconButton
                            sx={{
                                color: 'secondary.contrastText',
                                backgroundColor: 'success.main'
                            }}
                            component="label"
                            aria-label="export"
                            onClick={fetchData}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </ButtonGroup>
                    <CSVLink className="hidden" data={data} headers={headers} filename={`${project.name}.csv`} ref={csvLink} target="_blank" />
                </TableCell>
            </TableRow>
        </>
    );
};
