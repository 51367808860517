import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useDateFilter = ({ startDateInput, endDateInput }: {
    startDateInput: Dayjs,
    endDateInput: Dayjs
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate] = useState<Dayjs>(startDateInput);
    const [endDate, setEndDate] = useState<Dayjs>(endDateInput);


    useEffect(() => {
        if (!searchParams.get('startDate') || !searchParams.get('endDate')) {
            searchParams.set('startDate', startDate.format('YYYY-MM-DD'));
            searchParams.set('endDate', endDate.format('YYYY-MM-DD'));
            setSearchParams(searchParams);
        } else {
            setStartDate(dayjs(searchParams.get('startDate')));
            setEndDate(dayjs(searchParams.get('endDate')));
        }
    }, [searchParams]);

    const handleOnChangeStart = (date: Dayjs | null) => {
        if (date) {
            searchParams.set('startDate', date?.format('YYYY-MM-DD'));
            setSearchParams(searchParams);
        }
    };
    const handleOnChangeEnd = (date: Dayjs | null) => {
        if (date) {
            searchParams.set('endDate', date?.format('YYYY-MM-DD'));
            setSearchParams(searchParams);
        }
    };
    return { handleOnChangeEnd, handleOnChangeStart, endDate, startDate };
};
