import { AuthenticatedUser } from 'src/types/user';
import { Navigate, Outlet } from 'react-router-dom';

export const RedirectRoute = ({ user, redirectPath }: { user: AuthenticatedUser; redirectPath: string }) => {
    if (user.role) {
        return <Navigate to={redirectPath} replace />;
    }

    return <Outlet />;
};
