import { DialogTitle, DialogContent, Dialog, DialogContentText, DialogActions, Button } from '@mui/material';
import { Transition } from 'src/components';
import { destroy } from 'src/api/client';
import { Task } from 'src/types/task';
import { useSearchParams } from 'react-router-dom';

export const DeleteTaskDialog = ({
    task,
    openDeleteTaskModal,
    setOpenDeleteTaskModal
}: {
    task: Task;
    openDeleteTaskModal: boolean;
    setOpenDeleteTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const handleClose = () => {
        setOpenDeleteTaskModal(false);
    };
    const handleCloseAgree = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const taskId = event.currentTarget.id;
        await destroy(`tasks/${taskId}`);
        searchParams.append('task', 'true');
        setSearchParams(searchParams);
        setOpenDeleteTaskModal(false);
    };

    return (
        <Dialog open={openDeleteTaskModal} TransitionComponent={Transition} keepMounted onClose={handleClose}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>Permanently delete task: {task.name}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ backgroundColor: 'primary.main', color: 'secondary.contrastText' }} onClick={handleClose}>
                    Disagree
                </Button>
                <Button sx={{ backgroundColor: 'error.main', color: 'secondary.contrastText' }} id={task.id} onClick={handleCloseAgree}>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};
