const request = async (url: string, config: RequestInit) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const response: Response = await fetch(`${baseUrl}/${url}`, config);
    return response.json();
};

const defaultHeaders = (token = '') => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token || localStorage.getItem('token')}`
});

export const get = (url: string) => request(url, { method: 'GET', headers: defaultHeaders() });

export const post = <TBody extends BodyInit>(url: string, body: TBody) => request(url, { method: 'POST', body, headers: defaultHeaders() });

export const put = <TBody extends BodyInit>(url: string, body: TBody, token = '') => request(url, { method: 'PUT', body, headers: defaultHeaders(token) });

export const destroy = (url: string) => request(url, { method: 'DELETE', headers: defaultHeaders() });
