import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { destroy } from 'src/api/client';
import { Transition } from 'src/components';
import { useGetLocale } from 'src/hooks/useGetLocale';
import { Holiday } from 'src/types/holiday';

export const DeleteHolidayDialog = ({ holiday, openModal, setOpenModal }: { holiday: Holiday; openModal: boolean; setOpenModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const handleClose = () => {
        setOpenModal(false);
    };
    const { locale } = useGetLocale();
    const handleCloseAgree = async (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        await destroy(`days/${holiday.id}`);
        setOpenModal(false);
        searchParams.append('holiday', 'true');
        setSearchParams(searchParams);
    };

    return (
        <Dialog open={openModal} TransitionComponent={Transition} keepMounted onClose={handleClose}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Permanently delete {holiday.workOnPublicHoliday ? 'work on public holiday' : 'holiday'}: {locale && holiday.startDate.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' })} -{' '}
                    {locale && holiday.endDate.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ backgroundColor: 'primary.main', color: 'secondary.contrastText' }} onClick={handleClose}>
                    Disagree
                </Button>
                <Button sx={{ backgroundColor: 'error.main', color: 'secondary.contrastText' }} onClick={handleCloseAgree}>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};
