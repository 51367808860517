import { SyntheticEvent, useEffect, useState } from 'react';
import { FormControl, FormControlLabel, TextField, Typography, Checkbox, Autocomplete, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useAutocompleteProject } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CreateUpdateProjectInput, CreateUpdateProjectSchema } from 'src/types/projectForms';
import { zodResolver } from '@hookform/resolvers/zod';
import { User } from 'src/types/user';
import { get, put, post } from 'src/api/client';
import { GetProjectResponse, Project } from 'src/types/project';

export const CreateUpdateProjectForm = ({ projectId }: { projectId: string | undefined }) => {
    const [project, setProject] = useState<Project>();
    const { open, setOpen, options, loading } = useAutocompleteProject();
    const [users, setUsers] = useState<User[]>([]);
    const [checked, setChecked] = useState<boolean | undefined>(false);
    const navigate = useNavigate();
    const {
        register,
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit,
        setValue
    } = useForm<CreateUpdateProjectInput>({
        resolver: zodResolver(CreateUpdateProjectSchema)
    });

    useEffect(() => {
        (async () => {
            if (projectId) {
                const response: GetProjectResponse = await get(`projects/${projectId}?users=true&isDeleted=true`);
                setProject(response.data.project);
            }
        })();
    }, [projectId]);

    useEffect(() => {
        if (project) {
            setUsers(project.users);
            setValue('name', project.name);
            setChecked(project.isActive);
        }
    }, [project]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
            navigate('/projects');
        }
    }, [isSubmitSuccessful]);

    const onChange = (_: SyntheticEvent, value: User[]) => {
        setUsers(value);
    };

    const onSubmitHandler: SubmitHandler<CreateUpdateProjectInput> = async (values: CreateUpdateProjectInput) => {
        const userIds = users.map((user) => user.id);
        values.users = userIds;
        values.isActive = checked;
        if (project) {
            await put(`projects/${project.id}`, JSON.stringify(values));
        } else {
            await post('projects', JSON.stringify(values));
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <FormControl style={{ minWidth: '100%', minHeight: '85vh', marginTop: 10 }}>
                <Grid2
                    container
                    spacing={3}
                    justifyContent="center"
                    sx={{
                        margin: 'auto',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}
                >
                    <Grid2 xs={12}>
                        <Typography component="h3" variant="h6">
                            {project ? 'Update Project' : 'Create Project'}
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            fullWidth
                            label="Project Title*"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            sx={{ marginBottom: 3 }}
                            error={!!errors?.name}
                            helperText={errors?.name?.message}
                            {...register('name')}
                        />
                        <Autocomplete
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            value={users}
                            onChange={onChange}
                            isOptionEqualToValue={(option, value) => `${option.firstName} ${option.lastName}` === `${value.firstName} ${value.lastName}`}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            options={options}
                            loading={loading}
                            multiple
                            renderInput={(params) => <TextField name="users" {...params} label="Users" />}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={() => {
                                        setChecked(!checked);
                                    }}
                                />
                            }
                            {...register('isActive')}
                            label="Active"
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <Button type="submit" variant="contained">
                            Save
                        </Button>
                    </Grid2>
                </Grid2>
            </FormControl>
        </form>
    );
};
