import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { get, post, put } from 'src/api/client';
import { MoreThanAnnualDialog } from 'src/components/dialogs';
import { useGetIUserInformation } from 'src/hooks';
import { useGetLocale } from 'src/hooks/useGetLocale';
import { ConfigResponse } from 'src/types/configuration';
import { CreateHolidayInput } from 'src/types/holidayForm';
import { OptionType } from 'src/types/project';
import { CreateHolidaysResponse, GetUserResponse, ListHolidaysResponse, ListUserResponse } from 'src/types/response';
import { StateDialogContext } from 'src/types/stateContext';

export const HolidayForm = ({ userId, setIsOpen, holiday, isOpen }: StateDialogContext) => {
    const [minDate, setMinDate] = useState<Dayjs>(dayjs());
    const [searchParams, setSearchParams] = useSearchParams();
    const [values, setValues] = useState<CreateHolidayInput>()
    const { IUser } = useGetIUserInformation();
    const { locale } = useGetLocale();
    const [userOptions, setUserOptions] = useState<OptionType[]>([]);
    const [userLabel, setUserLabel] = useState<OptionType>({ label: '', id: '0' });
    const [startDate, setStartDate] = useState<Dayjs>(minDate);
    const [endDate, setEndDate] = useState<Dayjs>(minDate);
    const [isCreate, setIsCreate] = useState<boolean>(true);
    const [isMoreThanAnnualDays, setIsMoreThanAnnualDays] = useState<boolean>(false);
    const [totalDaysCount, setTotalDaysCount] = useState<number>(0);
    const [workOnPublicHoliday, setWorkOnPublicHoliday] = useState<boolean>(false)
    const handleStartDateOnChange = (value: Dayjs | null) => {
        if (value) {
            setStartDate(value);
        }
    };
    const handleEndDateOnChange = (value: Dayjs | null) => {
        if (value) {
            setEndDate(value);
        }
    };

    useEffect(() => {
        if (IUser.id){
            (async () => {
                const firstDayOfTheYear = dayjs().startOf('year').format('YYYY-MM-DD');
                const lastDayOfTheYear = dayjs().endOf('year').format('YYYY-MM-DD');
                const searchParameters = new URLSearchParams({
                    users: IUser.id,
                    startDate:firstDayOfTheYear,
                    endDate:lastDayOfTheYear,
                    status:'verified',
                    limit:'all'
                });
                const response: ListHolidaysResponse = await get(`days?${searchParameters.toString()}`);
                const holidays = response.data.items;
                let totalDays = 0;
                for (const holiday of holidays) {
                    totalDays += holiday.daysCount;
                    
                }
                setTotalDaysCount(totalDays);
            })();
        }
        if (IUser.role == 'admin'){
            setMinDate(dayjs().startOf('year'))
        }
    }, [IUser]);
    
    const onSubmitHandler: SubmitHandler<CreateHolidayInput> = async (values: CreateHolidayInput) => {
        values.user_id = +userLabel.id;
        if (startDate <= endDate) {
            values.startDate = startDate.format('YYYY-MM-DD');
            values.endDate = endDate.format('YYYY-MM-DD');
            values.workOnPublicHoliday = workOnPublicHoliday;
            setValues(values);
            const searchParameters = new URLSearchParams({
                startDate: values.startDate,
                endDate: values.endDate
            });
            const countResponse = await get(`days/count?${searchParameters.toString()}`);
            const currentCount = countResponse.data.count
            const allDaysOffCount = totalDaysCount + currentCount
            const siteSettingsResponse: ConfigResponse = await get('config?create=true')
            const annualDays = siteSettingsResponse.data.siteConfig.annualDays;
            if (annualDays && annualDays < allDaysOffCount && !workOnPublicHoliday && currentCount != 0){
                setIsMoreThanAnnualDays(true)
            }else{
                let response: CreateHolidaysResponse;
                if (isCreate) {
                    response = await post('days', JSON.stringify(values));
                } else {
                    response = await put(`days/${holiday?.id}`, JSON.stringify(values));
                }
                if (response.data.success) {
                    setIsOpen(false);
                    searchParams.append('holiday', 'true');
                    setSearchParams(searchParams);
                }
            }
            
        }
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<CreateHolidayInput>();

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (holiday) {
            setValue('description', holiday.description);
            setStartDate(dayjs(holiday.startDate));
            setEndDate(dayjs(holiday.endDate));
            setIsCreate(false);
            setMinDate(dayjs().startOf('year'));
            setWorkOnPublicHoliday(holiday.workOnPublicHoliday);
        }
    }, [holiday]);

    useEffect(() => {
        if (userId) {
            (async () => {
                const userResponse: GetUserResponse = await get(`users/${userId}`);
                setUserLabel({ id: userId, label: userResponse.data.user.firstName + ' ' + userResponse.data.user.lastName });
            })();
        }
    }, [isOpen, userId]);

    const handleOnChangeUser = (_: React.SyntheticEvent<Element, Event>, value: { label: string | undefined; id: string | undefined } | null) => {
        if (value?.id && value?.label) {
            setUserLabel({ label: value.label, id: value?.id });
        }
    };
    useEffect(() => {
        (async () => {
            const userResponse: ListUserResponse = await get('users');
            const options: OptionType[] = [];
            for (const user of userResponse.data.items) {
                const option = {
                    label: user.firstName + ' ' + user.lastName,
                    id: user.id
                };
                options.push(option);
            }
            setUserOptions(options);
        })();
    }, []);
    return (
        <>
            {isMoreThanAnnualDays && 
                <MoreThanAnnualDialog isOpen={isMoreThanAnnualDays} setIsOpen={setIsMoreThanAnnualDays} isCreate={isCreate} holiday={holiday} setIsGlobalOpen={setIsOpen} values={values}></MoreThanAnnualDialog>
            }
            {locale && (
                <LocalizationProvider adapterLocale={locale.toLocaleLowerCase()} dateAdapter={AdapterDayjs}>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormControl>
                            <Grid2 container={true} spacing={3} justifyContent={'flex-end'} sx={{ margin: 'auto' }}>
                                {IUser.role == 'admin' && (
                                    <Grid2 xs={12}>
                                        <Autocomplete
                                            options={userOptions}
                                            getOptionDisabled={(option) => option.id === '0'}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            value={{ label: userLabel?.label, id: userLabel?.id }}
                                            getOptionLabel={(option) => option?.label || ''}
                                            onChange={handleOnChangeUser}
                                            renderInput={(params) => <TextField {...params} label="User*" />}
                                        />
                                    </Grid2>
                                )}
                                <Grid2 xs={12} md={6}>
                                    <DatePicker value={startDate} minDate={minDate} label={'From Date'} slotProps={{ textField: { fullWidth: true } }} onChange={handleStartDateOnChange} />
                                </Grid2>
                                <Grid2 xs={12} md={6}>
                                    <DatePicker
                                        value={endDate}
                                        {...register('endDate')}
                                        minDate={startDate}
                                        label={'To Date'}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={handleEndDateOnChange}
                                    />
                                </Grid2>
                                <Grid2 xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        InputLabelProps={{ shrink: true }}
                                        label="Description"
                                        rows={4}
                                        multiline
                                        {...register('description')}
                                        error={!!errors?.description}
                                        helperText={errors?.description?.message}
                                    />
                                </Grid2>
                                <Grid2 xs={5} md={6} pr={{ xs:1, sm:2, md:22 }}>
                                    <FormControlLabel  control={<Checkbox checked={workOnPublicHoliday} onChange={(e, value)=> {setWorkOnPublicHoliday(value)}} />} label="Work on Public Holiday" />
                                </Grid2>
                                <Grid2 xs={2} md={3}>
                                </Grid2>
                                <Grid2 xs={5} md={3}>
                                    <Button variant="contained" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button sx={{ marginLeft: '10%', backgroundColor: 'secondary.main' }} type="submit" variant="contained">
                                        Save
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </FormControl>
                    </form>
                </LocalizationProvider>
            )}
        </>
    );
};
