import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OptionType } from 'src/types/project';
import { projectStatusOptions } from 'src/constants/options';

export const ProjectStatusFilter = () => {
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [statusId, setStatusId] = useState<string>();

    useEffect(() => {
        const foundOption = projectStatusOptions.find(status => status.id === statusId) ?? null;
        setSelectedOption(foundOption);
    }, [statusId]);


    useEffect(() => {

        setStatusId(searchParams.get('status') ?? '');
    }, [searchParams]);

    const handleOnChange = (_: SyntheticEvent<Element, Event>, value: OptionType | null) => {

        const updatedSearchParams = new URLSearchParams(searchParams);
        if (value) {
            updatedSearchParams.set('status', value.id);
        } else {
            updatedSearchParams.delete('status');
        }
        setSearchParams(updatedSearchParams);
    };

    return (
        <Autocomplete
            options={projectStatusOptions}
            value={selectedOption}
            onChange={handleOnChange}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={(option) => option?.label || ''}
            renderInput={(params) => <TextField {...params} label="Status"/>}
        />
    );
};
