import { TableRow, TableCell, ButtonGroup, IconButton } from '@mui/material';
import React, { useState, MouseEvent,useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSearchParams } from 'react-router-dom';
import { TaskRowFilterContext } from 'src/types/filters';
import { CreateUpdateTaskDialog, DeleteTaskDialog, StyledLink } from 'src/components';
import dayjs from 'dayjs';
import { useGetLocale } from 'src/hooks/useGetLocale';

export const TaskRow = ({ task, isMyTask, selectedTask }: TaskRowFilterContext) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openDeleteTaskModal, setOpenDeleteTaskModal] = useState<boolean>(false);
    const { locale } = useGetLocale();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (selectedTask) {
            setOpenDialog(true);
        }
    }, [selectedTask]);

    const handleOpenTaskForm = () => {
        searchParams.set('taskId', task.id);
        setSearchParams(searchParams);
        setOpenDialog(!openDialog);
    };

    const handleClickOpen = () => {
        setOpenDeleteTaskModal(true);
    };

    const handleSetDate = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        searchParams.set('startDate', dayjs(task.date).format('YYYY-MM-DD'));
        searchParams.set('endDate', dayjs(task.date).format('YYYY-MM-DD'));
        setSearchParams(searchParams);
    };
    const handleSetProject = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        searchParams.set('projects', task.project.id);
        setSearchParams(searchParams);
    };

    const handleSetUser = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        searchParams.set('users', task.user.id);
        setSearchParams(searchParams);
    };
    task.date = new Date(task.date);

    return (
        <React.Fragment>
            <CreateUpdateTaskDialog isOpen={openDialog} setIsOpen={setOpenDialog} taskId={task.id} />
            <DeleteTaskDialog task={task} openDeleteTaskModal={openDeleteTaskModal} setOpenDeleteTaskModal={setOpenDeleteTaskModal}></DeleteTaskDialog>
            <TableRow>
                <TableCell component="th" scope="row" align="center">
                    {locale && (
                        <StyledLink to="#" onClick={handleSetDate}>
                            {task.date.toLocaleDateString(locale, { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' })}
                        </StyledLink>
                    )}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                    <StyledLink to="#" onClick={handleSetProject}>
                        {task.project.name}
                    </StyledLink>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                    <StyledLink to="#" onClick={handleSetUser}>
                        {task.user.firstName + ' ' + task.user.lastName}
                    </StyledLink>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                    {task.name}
                </TableCell>
                <TableCell align="center">{task.timeSpent}</TableCell>
                <TableCell align="center" sx={{ padding: 0 }}>
                    {isMyTask && (
                        <ButtonGroup disableElevation variant="contained" color="secondary">
                            <IconButton
                                sx={{
                                    color: 'secondary.contrastText',
                                    backgroundColor: 'secondary.main'
                                }}
                                size="small"
                                component="label"
                                aria-label="edit"
                                onClick={handleOpenTaskForm}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                sx={{
                                    color: 'secondary.contrastText',
                                    backgroundColor: 'error.main'
                                }}
                                size="small"
                                component="label"
                                aria-label="delete"
                                onClick={handleClickOpen}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ButtonGroup>
                    )}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};
