import { Dispatch, SetStateAction } from 'react';
import { object, string } from 'zod';

export const CreateTaskSchema = object({
    name: string().max(32, ' Name must be less than 100 characters'),
    description: string()
});

export type CreateTaskInput = {
    name: string;
    project_id: number;
    user_id: number;
    description: string;
    date: string;
    timeSpent?: string;
};

export type StateUpdateTaskContext = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    taskId?: string;
};
