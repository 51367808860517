import { TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import { get } from 'src/api/client';
import { Holiday } from 'src/types/holiday';
import { ListHolidaysResponse } from 'src/types/response';
import { User } from 'src/types/user';

export const UserSpecificHolidayRow = ({ user }: { user: User }) => {
    const [holidays, setHolidays] = useState<Holiday[]>([]);
    const [annualDays, setAnnualDays] = useState<number>(0);
    const [totalVerified, setTotalVerified] = useState<number>(0);
    const [totalPending, setTotalPending] = useState<number>(0);

    useEffect(() => {
        (async () => {
            const firstDayOfTheYear = dayjs().startOf('year').format('YYYY-MM-DD');
            const endDayOfTheYear = dayjs().endOf('year').format('YYYY-MM-DD');
            const searchParameters = new URLSearchParams({
                users: user.id,
                startDate: firstDayOfTheYear,
                endDate: endDayOfTheYear,
            });
            const response: ListHolidaysResponse = await get(`days?${searchParameters.toString()}`);
            setHolidays(response.data.items);
            const siteConfig = await get('config?create=true');
            if (JSON.parse(siteConfig.data.success)) {
                setAnnualDays(siteConfig.data.siteConfig.annualDays);
            }
        })();
    }, []);

    useEffect(() => {
        let verifiedDays = 0;
        let pendingDays = 0;
        for (const holiday of holidays) {
            if (holiday.status == 'verified') {
                verifiedDays += holiday.daysCount;
            }else if (holiday.status == 'pending'){
                pendingDays += holiday.daysCount;

            }
        }
        setTotalVerified(verifiedDays);
        setTotalPending(pendingDays);
    }, [holidays]);
    return (
        <Fragment>
            <TableRow >
                <TableCell align="left">{user.firstName} {user.lastName}</TableCell>
                <TableCell align="center">{totalVerified}</TableCell>
                <TableCell align="center">{totalPending}</TableCell>
                <TableCell align="center">{annualDays - totalVerified}</TableCell>
            </TableRow>
        </Fragment>
    );
};
