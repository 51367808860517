import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { get } from 'src/api/client';
import { ListTaskResponse } from 'src/types/response';
import { TaskToExport } from 'src/types/task';
import { useGetLocale } from './useGetLocale';

export const useExport = (userId: string | undefined, projectId: string | undefined) => {
    const [searchParams] = useSearchParams();
    const [userIds, setUserIds] = useState<string[]>();
    const [projectIds, setProjectIds] = useState<string[]>();
    const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [data, setData] = useState<TaskToExport[]>([]);
    const { locale } = useGetLocale();

    useEffect(() => {
        const searchParamsUsers = searchParams.get('users');
        const searchParamsProjects = searchParams.get('projects');
        if (searchParamsUsers) {
            setUserIds(searchParamsUsers.split(','));
        }
        if (userId) {
            setUserIds([userId]);
        }
        if (searchParamsProjects) {
            setProjectIds(searchParamsProjects.split(','));
        }
        if (projectId) {
            setProjectIds([projectId]);
        }
    }, [searchParams, projectId, userId]);

    const fetchData = async () => {
        const startDate = searchParams.get('startDate');
        const endDate = searchParams.get('endDate');
        const searchParameters = new URLSearchParams({
            startDate: startDate || '',
            endDate: endDate || '',
            limit: 'all'
        });
        if (userIds) {
            for (const userId of userIds) {
                searchParameters.append('users[]', userId);
            }
        }
        if (projectIds) {
            for (const projectId of projectIds) {
                searchParameters.append('projects[]', projectId);
            }
        }
        const endpoint = `tasks?${searchParameters.toString()}`;
        const response: ListTaskResponse = await get(endpoint);
        const tasksToExport = [];

        for (const task of response.data.items) {
            const taskToExport = {} as TaskToExport;
            if (locale) {
                taskToExport.date = new Date(task.date).toLocaleDateString(locale, { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' });
            }
            taskToExport.userName = task.user.firstName + ' ' + task.user.lastName;
            taskToExport.projectName = task.project.name;

            taskToExport.reportTitle = task.name;
            taskToExport.description = task.description.replace(/"/gi, '""');

            const timeSpent = task.timeSpentMinutes / 60;
            taskToExport.reportTime = timeSpent.toFixed(2);
            tasksToExport.push(taskToExport);
        }
        setData(tasksToExport);
    };

    useEffect(() => {
        if (data && data.length > 0) {
            csvLink?.current?.link.click();
        }
    }, [data]);

    return { fetchData, data, csvLink };
};
