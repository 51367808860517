import React, { JSXElementConstructor } from 'react';
import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<JSX.Element, string | JSXElementConstructor<JSX.Element>>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
