import { DialogTitle, Divider, DialogContent, Dialog } from '@mui/material';
import { useEffect } from 'react';
import { StateDialogContext } from 'src/types/stateContext';
import { CreateUpdateTaskForm, Transition } from 'src/components';
import { useSearchParams } from 'react-router-dom';

export const CreateUpdateTaskDialog = ({ isOpen, setIsOpen, taskId }: StateDialogContext) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setIsOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        searchParams.delete('taskId');
        setSearchParams(searchParams);
        setIsOpen(false);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} TransitionComponent={Transition} keepMounted fullWidth={true} maxWidth={'md'} sx={{ textAlign: 'center' }}>
            <DialogTitle>New Time Report</DialogTitle>
            <DialogContent>
                <Divider variant="middle" />
                {isOpen && <CreateUpdateTaskForm setIsOpen={setIsOpen} taskId={taskId} />}
            </DialogContent>
        </Dialog>
    );
};
