import { Outlet } from 'react-router-dom';
import { ResponsiveAppBar } from 'src/components';
import { useSetLocale } from '../../hooks/useSetLocale';

export const LayoutComponent = () => {
    useSetLocale();
    return (
        <>
            <ResponsiveAppBar />
            <Outlet />
        </>
    );
};
