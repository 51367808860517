import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { get } from 'src/api/client';
import { UserSpecificHolidayRow } from 'src/components';
import { ListUserResponse } from 'src/types/response';
import { User } from 'src/types/user';

export const UserSpecificHolidayTable = () => {
    const [users,setUsers] = useState<User[]>()
    const year = dayjs().year()

    useEffect(() => {
        (async () => {
            const usersResponse: ListUserResponse = await get('users');
            setUsers(usersResponse.data.items)
        })();
    }, []);

    return (
        <Grid2 container={true} spacing={2} justifyContent={'center'} sx={{ marginTop: 15,marginBottom: 15, textAlign: 'center' }}>
            <Grid2 xs={12}>
                <Typography component="h3" variant="h4">
                    {year} User-Specific Holiday Summary
                </Typography>
                <Divider sx={{ maxWidth: '50%', margin: 'auto' }} />
            </Grid2>
            <TableContainer sx={{ width: { xs: '100%', md: '80%' }, marginTop: 2 }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">User</TableCell>
                            <TableCell align="center">Verified Holidays</TableCell>
                            <TableCell align="center">Pending Holidays</TableCell>
                            <TableCell align="center">Remaining Annual Leave Days</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users?.map((user) => (
                            <UserSpecificHolidayRow key={user.id} user={user}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid2>
    );
};
