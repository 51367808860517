import '../constants/languages';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { get } from 'src/api/client';
import { LANGUEGES } from 'src/constants/availableLanguages';
import { Config, ConfigResponse } from 'src/types/configuration';
import { useGetIUserInformation } from './useLocalStorage';

export const useSetLocale = () => {
    const [config, setConfig] = useState<Config>();
    const [locale, setLocale] = useState<string>('bg');
    const [canSetLocale, setCanSetLocale] = useState<boolean>(false);
    const { IUser } = useGetIUserInformation();
    dayjs.locale(locale);
    useEffect(() => {
        if (IUser.id) {
            (async () => {
                const siteConfig: ConfigResponse = await get('config?create=true');
                if (siteConfig.data.success) {
                    setConfig(siteConfig.data.siteConfig);
                }
            })();
        }
    }, [IUser]);
    useEffect(() => {
        if (config && LANGUEGES.includes(config.localeCode.toLowerCase())) {
            if (config.localeCode.toLowerCase() == 'cn') {
                setLocale('zh');
            } else if (config.localeCode.toLowerCase() == 'gb') {
                setLocale('en');
            } else {
                setLocale(config.localeCode.toLowerCase());
            }
            localStorage.setItem('locale', config.localeCode.toUpperCase());
            setCanSetLocale(true);
        } else if (config && !LANGUEGES.includes(config.localeCode.toLowerCase())) {
            localStorage.setItem('locale', 'EN');
            setCanSetLocale(true);
        }

        if (config === null) {
            localStorage.setItem('locale', locale.toUpperCase());
            setCanSetLocale(true);
        }
    }, [config, IUser]);

    return { locale, canSetLocale };
};
