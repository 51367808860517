import { Router } from './router';
import { ThemeProvider } from '@mui/material/styles';
import { mainTheme } from './theme';

export default function App() {
    return (
        <ThemeProvider theme={mainTheme}>
            <Router />
        </ThemeProvider>
    );
}
