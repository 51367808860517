import { useEffect, useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Tooltip, MenuItem, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StringToStringObject, adminSettingsElements, developerSettingsElements, moderatorSettingsElements } from 'src/types/navElements';
import { useGetIUserInformation } from 'src/hooks';
import { CreateUpdateTaskDialog } from 'src/components';
import EditIcon from '@mui/icons-material/Edit';

export const ResponsiveAppBar: React.FC = () => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [settingsElements, setSettingsElements] = useState<StringToStringObject>({} as StringToStringObject);
    const { IUser } = useGetIUserInformation();
    const navigate = useNavigate();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenTaskForm = () => {
        setOpenDialog(!openDialog);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleNavigate = (key: string) => {
        handleCloseUserMenu();
        navigate(key);
    };

    const handleLogOut = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    useEffect(() => {
        if (IUser.role == 'admin') {
            setSettingsElements(adminSettingsElements);
        } else if (IUser.role == 'moderator') {
            setSettingsElements(moderatorSettingsElements);
        } else if (IUser.role == 'developer') {
            setSettingsElements(developerSettingsElements);
        }
    }, [IUser]);
    return (
        <>
            <CreateUpdateTaskDialog isOpen={openDialog} setIsOpen={setOpenDialog} taskId="" />
            <AppBar position="fixed">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#"
                            onClick={() => {
                                navigate('/');
                            }}
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontWeight: 800
                            }}
                        >
                            <img src="/images/logo.png" alt="mtr-design logo" />
                            Time Tracker
                        </Typography>
                        {IUser.username && (
                            <Button variant="contained" onClick={handleOpenTaskForm} sx={{ backgroundColor: 'secondary.main', display: { xs: 'none', md: 'flex' } }} endIcon={<EditIcon />}>
                                Add Report
                            </Button>
                        )}

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}></Box>
                        <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, position: 'fixed' }}>
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="#"
                                onClick={() => {
                                    navigate('/');
                                }}
                                sx={{
                                    mr: 2,
                                    display: { xs: 'flex', md: 'none' },
                                    fontWeight: 800
                                }}
                            >
                                <img src="/images/logo.png" alt="mtr-design logo" />
                            </Typography>
                            {IUser.username && (
                                <Button variant="contained" onClick={handleOpenTaskForm} color={'secondary'} endIcon={<EditIcon />}>
                                    Add Report
                                </Button>
                            )}
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>
                        {IUser.username && (
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open navigation">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt={IUser.username.toUpperCase()} src="#" />
                                    </IconButton>
                                </Tooltip>
                                <Menu sx={{ mt: '45px' }} anchorEl={anchorElUser} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                                    {Object.keys(settingsElements).map((key) => (
                                        <MenuItem key={key} onClick={() => handleNavigate(key)}>
                                            <Typography textAlign="center">{settingsElements[key]}</Typography>
                                        </MenuItem>
                                    ))}
                                    <MenuItem key={'logout'} onClick={handleLogOut}>
                                        <Typography textAlign="center">Log Out</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};
