import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ChangeEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { put } from 'src/api/client';
import { useGetIUserInformation } from 'src/hooks';
import { User } from 'src/types/user';
import { UpdateUserInput, UpdateUserSchema } from 'src/types/userForms';

export const UpdateUserForm = ({ user }: { user: User }) => {
    const { IUser } = useGetIUserInformation();
    const [role, setRole] = useState<string>(user.role);

    const {
        register,
        formState: { errors, isSubmitSuccessful },
        handleSubmit,
        setError
    } = useForm<UpdateUserInput>({
        resolver: zodResolver(UpdateUserSchema)
    });
    const setRoleValue = (_: ChangeEvent<HTMLInputElement>, value: string) => {
        setRole(value);
    };

    const onSubmitHandler: SubmitHandler<UpdateUserInput> = async (values: UpdateUserInput) => {
        if (!values.password) {
            delete values['password'];
        }
        if (user) {
            values.role = role;
            const response = await put(`users/${user.id}`, JSON.stringify(values));
            if (!response.data.success) {
                setError('oldPassword', { type: 'custom', message: 'The password does not match the old password' });
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <FormControl>
                <Grid2
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{
                        marginTop: 8,
                        alignItems: 'center',
                        textAlign: 'center',
                        minHeight: '80vh'
                    }}
                >
                    {isSubmitSuccessful && (
                        <Alert severity="success" sx={{ maxWidth: { xs: '50%', md: '40%', xl: '25%' }, marginLeft: 'auto', marginRight: 'auto' }}>
                            Successfully updated user
                        </Alert>
                    )}
                    <Grid2 xs={12}>
                        <Typography component="h3" variant="h6">
                            Login Information
                        </Typography>
                    </Grid2>
                    <Grid2 xs={9} sm={9} md={4}>
                        <TextField fullWidth defaultValue={user.email} label="Email*" variant="standard" error={!!errors?.email} helperText={errors?.email?.message} {...register('email')} />
                        <TextField
                            fullWidth
                            label="Username*"
                            defaultValue={user.username}
                            variant="standard"
                            error={!!errors?.username}
                            helperText={errors?.username?.message}
                            {...register('username')}
                        />
                    </Grid2>
                    <Grid2 xs={9} sm={9} md={4}>
                        <TextField
                            fullWidth
                            type="password"
                            defaultValue=""
                            label="Old Password"
                            variant="standard"
                            error={!!errors?.oldPassword}
                            helperText={errors?.oldPassword?.message}
                            {...register('oldPassword')}
                        />
                        <TextField
                            fullWidth
                            type="password"
                            defaultValue=""
                            label="New Password"
                            variant="standard"
                            error={!!errors?.password}
                            helperText={errors?.password?.message}
                            {...register('password')}
                        />
                        <TextField
                            fullWidth
                            type="password"
                            defaultValue=""
                            label="Confirm Password"
                            variant="standard"
                            error={!!errors?.passwordConfirm}
                            helperText={errors?.passwordConfirm?.message}
                            {...register('passwordConfirm')}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <Typography component="h3" variant="h6">
                            Personal Information
                        </Typography>
                    </Grid2>
                    <Grid2 xs={9} sm={9} md={4}>
                        <TextField
                            fullWidth
                            defaultValue={user.firstName}
                            label="First Name*"
                            variant="standard"
                            error={!!errors?.firstName}
                            helperText={errors?.firstName?.message}
                            {...register('firstName')}
                        />
                        <TextField
                            fullWidth
                            defaultValue={user.lastName}
                            label="Last Name*"
                            variant="standard"
                            error={!!errors?.lastName}
                            helperText={errors?.lastName?.message}
                            {...register('lastName')}
                        />
                    </Grid2>
                    <Grid2 xs={9} sm={9} md={4}>
                        <TextField
                            fullWidth
                            defaultValue={user.jobTitle}
                            label="Job Title"
                            variant="standard"
                            error={!!errors?.jobTitle}
                            helperText={errors?.jobTitle?.message}
                            {...register('jobTitle')}
                        />
                        <TextField
                            fullWidth
                            defaultValue={user.phoneNumber}
                            label="Phone Number"
                            variant="standard"
                            error={!!errors?.phoneNumber}
                            helperText={errors?.phoneNumber?.message}
                            {...register('phoneNumber')}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <FormLabel>Role</FormLabel>
                        <RadioGroup row defaultValue={user?.role} onChange={setRoleValue} style={{ justifyContent: 'center' }}>
                            <FormControlLabel disabled={IUser.role != 'admin'} value="admin" control={<Radio />} label="Admin" />
                            <FormControlLabel disabled={IUser.role != 'admin'} value="developer" name="developer" control={<Radio />} label="Developer" />
                            <FormControlLabel disabled={IUser.role != 'admin'} value="moderator" name="moderator" control={<Radio />} label="Moderator" />
                        </RadioGroup>
                    </Grid2>
                    <Grid2 xs={12}>
                        <FormControlLabel control={<Checkbox disabled={IUser.role != 'admin'} defaultChecked={user.sendEmails} {...register('sendEmails')} />} label="Send the user an email notification regarding the outstanding reports." />
                    </Grid2>

                    <Grid2 xs={12}>
                        <Button type="submit" variant="contained">
                            Update User
                        </Button>
                    </Grid2>
                </Grid2>
            </FormControl>
        </form>
    );
};
