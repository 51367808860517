export type StringToStringObject = {
    [key: string]: string;
};

export const adminSettingsElements: StringToStringObject = {
    '/profile': 'Profile',
    '/users?status=active': 'Users',
    '/projects': 'Projects',
    '/holidays': 'Holidays',
    '/configuration': 'Configuration'
};

export const moderatorSettingsElements: StringToStringObject={
    '/profile': 'Profile',
    '/users?status=active': 'Users',
    '/projects': 'Projects',
    '/holidays': 'Holidays'
};
export const developerSettingsElements: StringToStringObject = {
    '/profile': 'Profile',
    '/holidays': 'Holidays'
};
