import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, Button, TextField } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { DatePicker, LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { SyntheticEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { get, post, put } from 'src/api/client';
import { useAutocompleteProjectOptions, useGetIUserInformation, useGetTask } from 'src/hooks';
import { useGetLocale } from 'src/hooks/useGetLocale';
import { OptionType } from 'src/types/project';
import { CreateUpdateTaskResponse, GetUserResponse, ListUserResponse } from 'src/types/response';
import { CreateTaskInput, CreateTaskSchema, StateUpdateTaskContext } from 'src/types/taskForm';

export const CreateUpdateTaskForm = ({ setIsOpen, taskId }: StateUpdateTaskContext) => {
    const { IUser } = useGetIUserInformation();
    const [searchParams, setSearchParams] = useSearchParams();
    const task = useGetTask(taskId);
    const { locale } = useGetLocale();
    const [userOptions, setUserOptions] = useState<OptionType[]>([]);
    const [projectError, setProjectError] = useState<string>();
    const [hoursError, setHoursError] = useState<string>();
    const [nameError, setNameError] = useState<string>();
    const [descriptionError, setDescriptionError] = useState<string>();
    const [success, setSuccess] = useState<boolean>(false);

    const { register, handleSubmit, setValue } = useForm<CreateTaskInput>({
        resolver: zodResolver(CreateTaskSchema)
    });
    const [date, setDate] = useState<Dayjs | null>(dayjs());
    const [hours, setHours] = useState<Dayjs | null>(dayjs().set('hours', 0).set('minute', 0));
    const [projectLabel, setProjectLabel] = useState<OptionType>({ label: '', id: '0' });
    const [userLabel, setUserLabel] = useState<OptionType>({ label: '', id: '0' });
    const { options } = useAutocompleteProjectOptions(IUser.id, true);

    useEffect(() => {
        if (IUser.id) {
            (async () => {
                const userResponse: GetUserResponse = await get(`users/${IUser.id}`);
                setUserLabel({ id: IUser.id, label: userResponse.data.user.firstName + ' ' + userResponse.data.user.lastName });
            })();
        }
    }, [IUser]);

    useEffect(() => {
        (async () => {
            const userResponse: ListUserResponse = await get('users');
            const options: OptionType[] = [];
            for (const user of userResponse.data.items) {
                const option = {
                    label: user.firstName + ' ' + user.lastName,
                    id: user.id
                };
                options.push(option);
            }
            setUserOptions(options);
        })();
    }, []);

    useEffect(() => {
        if (task) {
            const userId: number = task.UserId;
            (async () => {
                if (userId) {
                    const userResponse: GetUserResponse = await get(`users/${userId}`);
                    setUserLabel({ id: userId.toString(), label: userResponse.data.user.firstName + ' ' + userResponse.data.user.lastName });
                }
            })();
            const spentHours = Math.floor(task.timeSpentMinutes / 60);
            const spentMinutes = task.timeSpentMinutes % 60;
            setValue('name', task.name);
            setValue('description', task.description);
            setDate(dayjs(task.date));
            setProjectLabel({ label: task.project.name, id: task.project.id.toString() });
            setHours(dayjs().set('hours', spentHours).set('minute', spentMinutes));
        } else {
            const projects = searchParams.get('projects');
            if (projects) {
                const projectId = projects.split(',')[0];
                for (const projectLabel of options) {
                    if (projectLabel.id == projectId) {
                        setProjectLabel(projectLabel);
                    }
                }
            }
        }
    }, [task, searchParams, options]);

    useEffect(() => {
        if (success) {
            searchParams.append('task', 'true');
            setSearchParams(searchParams);
            setIsOpen(false);
        }
    }, [success]);

    const onSubmitHandler: SubmitHandler<CreateTaskInput> = async (values: CreateTaskInput) => {
        let response: CreateUpdateTaskResponse = {} as CreateUpdateTaskResponse;
        let isValid = true;
        if (+projectLabel.id == 0) {
            setProjectError('Please add project');
            isValid = false;
        }

        if (hours && +hours?.format('HH:mm').replace(':', '.') == 0) {
            setHoursError('Please add spent time');
            isValid = false;
        }
        if (!values.name) {
            setNameError('Please add name');
            isValid = false;
        }

        if (!values.description) {
            setDescriptionError('Please add description');
            isValid = false;
        }

        if (+projectLabel.id != 0 && hours && +hours?.format('HH:mm').replace(':', '.') != 0 && values.name && values.description) {
            isValid = true;
        }

        if (userLabel.id && date && projectLabel && isValid) {
            values.project_id = +projectLabel.id;
            values.date = date.format();
            values.user_id = +userLabel.id;
            values.timeSpent = hours?.format('HH:mm').replace(':', '.');
            if (task) {
                response = await put(`tasks/${task.id}`, JSON.stringify(values));
            } else {
                response = await post('tasks', JSON.stringify(values));
            }
            if (response.data.success) {
                setSuccess(true);
            }
        }
    };

    const handleOnChange = (_: SyntheticEvent<Element, Event>, value: { label: string | undefined; id: string | undefined } | null) => {
        if (value?.id && value?.label) {
            setProjectLabel({ label: value.label, id: value?.id });
        }
    };

    const handleOnChangeUser = (_: SyntheticEvent<Element, Event>, value: { label: string | undefined; id: string | undefined } | null) => {
        if (value?.id && value?.label) {
            setUserLabel({ label: value.label, id: value?.id });
        }
    };

    const handleClose = () => {
        setIsOpen(false);
        searchParams.delete('taskId');
        setSearchParams(searchParams);
    };

    const handleSetDate = (newDate: Dayjs | null) => {
        if (newDate) {
            let date = newDate.hour(12);
            date = date.minute(0);
            setDate(date);
        }
    };

    return (
        <>
            {locale && (
                <LocalizationProvider adapterLocale={locale.toLocaleLowerCase()} dateAdapter={AdapterDayjs}>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <Grid2
                            container={true}
                            spacing={3}
                            justifyContent={'flex-end'}
                            sx={{
                                marginTop: '2%'
                            }}
                        >
                            {IUser.role == 'admin' && (
                                <Grid2 xs={12}>
                                    <Autocomplete
                                        options={userOptions}
                                        getOptionDisabled={(option) => option.id === '0'}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={{ label: userLabel?.label, id: userLabel?.id }}
                                        getOptionLabel={(option) => option?.label || ''}
                                        onChange={handleOnChangeUser}
                                        renderInput={(params) => <TextField {...params} label="User*" />}
                                    />
                                </Grid2>
                            )}
                            <Grid2 xs={12} sm={8} md={9} xl={9}>
                                <Autocomplete
                                    options={options}
                                    getOptionDisabled={(option) => option.id === '0'}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={{ label: projectLabel?.label, id: projectLabel?.id }}
                                    getOptionLabel={(option) => option?.label || ''}
                                    onChange={handleOnChange}
                                    renderInput={(params) => <TextField error={projectError ? true : false} helperText={projectError} {...params} label="Project*" />}
                                />
                            </Grid2>
                            <Grid2 xs={12} sm={4} md={3} xl={3}>
                                <DatePicker disableFuture formatDensity="spacious" label="Date" value={date} onChange={handleSetDate} />
                            </Grid2>
                            <Grid2 xs={12} sm={8} md={9} xl={9}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    label="Name*"
                                    variant="outlined"
                                    error={nameError ? true : false}
                                    helperText={nameError}
                                    {...register('name')}
                                />
                            </Grid2>
                            <Grid2 xs={12} sm={4} md={3} xl={3}>
                                <TimeField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    label="Spent Time in Hours*"
                                    variant="outlined"
                                    format="HH:mm"
                                    FormHelperTextProps={{
                                        sx: {
                                            color: 'error.main'
                                        }
                                    }}
                                    helperText={hoursError}
                                    value={hours}
                                    onChange={(newValue) => setHours(newValue)}
                                />
                            </Grid2>
                            <Grid2 xs={12}>
                                <TextField
                                    fullWidth={true}
                                    InputLabelProps={{ shrink: true }}
                                    label="Description*"
                                    rows={4}
                                    multiline
                                    {...register('description')}
                                    error={descriptionError ? true : false}
                                    helperText={descriptionError}
                                    inputProps={{
                                        style: {
                                            resize: 'vertical'
                                        }
                                    }}
                                />
                            </Grid2>
                            <Grid2 xs={5} sm={5} md={3} xl={3}>
                                <Button color="primary" variant="contained" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button sx={{ marginLeft: '10%' }} type="submit" variant="contained" color="secondary">
                                    Save
                                </Button>
                            </Grid2>
                        </Grid2>
                    </form>
                </LocalizationProvider>
            )}
        </>
    );
};
