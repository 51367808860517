import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get } from 'src/api/client';
import { UserRow } from 'src/components';
import { ListUserResponse } from 'src/types/response';
import { User } from 'src/types/user';

export const UserTable = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [searchParams] = useSearchParams();

    const status = searchParams.get('status');
    const role = searchParams.get('role');
    const filterUsers = searchParams.getAll('users');
    useEffect(() => {
        (async () => {
            const searchParameters = new URLSearchParams({});
            if (status) {
                searchParameters.append('status', status);
            }
            if (role) {
                searchParameters.append('role', role);
            }
            if (filterUsers) {
                for (const userId of filterUsers ){
                    searchParameters.append('userIds', userId);
                }
            }
            searchParameters.append('isDeleted', true.toString());
            const response: ListUserResponse = await get(`users?${searchParameters.toString()}`);
            setUsers(response.data.items);
        })();
    }, [searchParams]);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Username</TableCell>
                        <TableCell align="center">First Name</TableCell>
                        <TableCell align="center">Last Name</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Role</TableCell>
                        <TableCell align="center">Job Title</TableCell>
                        <TableCell align="center">Phone number</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users?.map((user) => (
                        <UserRow key={user.id} user={user} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
